// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwitchWithLabel-module__container___y2bhY{font-family:Proxima Nova;font-style:normal;font-weight:600;font-size:14px;line-height:20px;letter-spacing:0px;color:#58637e;height:20px;display:flex;align-items:center;margin-left:auto}.SwitchWithLabel-module__container___y2bhY.SwitchWithLabel-module__wide___q5tUH{width:100%;margin-bottom:16px}.SwitchWithLabel-module__label___eUi5H{cursor:pointer}.SwitchWithLabel-module__switch___HY0wM{margin-left:auto}.SwitchWithLabel-module__tooltip___uATy6{display:inline;color:#8992a9;margin-left:8px}.SwitchWithLabel-module__tooltip___uATy6 .MuiIconButton-root{margin-top:-3px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SwitchWithLabel-module__container___y2bhY`,
	"wide": `SwitchWithLabel-module__wide___q5tUH`,
	"label": `SwitchWithLabel-module__label___eUi5H`,
	"switch": `SwitchWithLabel-module__switch___HY0wM`,
	"tooltip": `SwitchWithLabel-module__tooltip___uATy6`
};
export default ___CSS_LOADER_EXPORT___;
