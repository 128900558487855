import { Pagination, Sorting, ListConfigApi } from '@workerbase/types/ListConfig';
import { Paths } from '@workerbase/utils/TS';
import { SkillGET } from '@workerbase/types/api/skills';
import { RoleGET } from '@workerbase/types/api/roles';
import { LicenseUsageGET, UserGET, UserPOST, UserPUT } from '@workerbase/types/api/users';

import { ListArgs, GraphQLListResponse, GroupOperation, GraphQLGroupResponse, PageInfo } from 'services/types/GraphQL';
import { User, UsersCategories } from 'services/types/User';
import { PaginationMeta } from '@workerbase/types/Response';
import { isString } from 'lodash';
import { useDispatch } from 'react-redux';
import { updateLoggedInUser } from '@redux/Login';
import { makeGraphqlGroupRequest, makeGraphqlListRequest } from './graphql';
import { useApiRequest, Options } from '../../hooks/useRequest';

import { api } from './api';
import { normalizeUser } from '../normalizers/users';

const USERS_ENDPOINT = '/api/v1/users';
const USERS_GRAPHQL_MODEL = 'users';

type UserGraphQlPaths = Paths<Omit<UserGET, 'roles' | 'skills'>, 2> | UserRolesGraphQlPaths | UserSkillsGraphQlPaths;

type UserRolesGraphQlPaths = Paths<{ roles: RoleGET }, 1>;
type UserSkillsGraphQlPaths = Paths<{ skills: SkillGET }, 1>;

const defaultPagination: Pagination = {
  page: 1,
  perPage: 20,
};

const defaultFields: UserGraphQlPaths[] = [
  '_id',
  'firstName',
  'lastName',
  'active',
  'language',
  'department',
  'email',
  'phoneNumber',
  'roles._id',
  'roles.description',
  'roles.name',
  'roles.apps',
  'roles.deviceApps',
  'skills._id',
  'skills.name',
  'shiftPlan',
  'superUser',
  'watchOnly',
  'disableDeviceLock',
  'isDeveloper',
  'location.name',
  'barcodeCredentials.identifier',
  'usageType',
];

type GetUsers = (args: {
  page?: number;
  perPage?: number;
  sorting?: Sorting;
  textSearch?: string;
  category?: UsersCategories;
  usersIds?: string[];
  fields?: UserGraphQlPaths[];
  preciseCount?: boolean;
}) => Promise<GetUsersResponse>;
export type UsersMeta = PageInfo & Pick<PaginationMeta, 'category' | 'stats'>;
export interface GetUsersResponse {
  data: User[];
  meta: UsersMeta;
}
export { UsersCategories };
export type GetUsersParams = Parameters<GetUsers>;
export const getUsers: GetUsers = async (params) => {
  const {
    page = 1,
    perPage = defaultPagination.perPage,
    sorting,
    textSearch,
    fields = defaultFields,
    usersIds,
    category,
    preciseCount = true,
  } = params;
  if (isString(category) && !Object.values(UsersCategories).includes(category)) {
    throw new Error("Category is invalid or doesn't exist");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy code to be refactored
  let filter: any = { deleted: { EQ: false } };
  if (category) {
    filter = {
      ...filter,
      active: { EQ: category === UsersCategories.ACTIVE },
    };
  }

  if (usersIds) {
    filter = {
      ...filter,
      _id: { IN: [...new Set(usersIds)] },
    };
  }

  const gqlArgs: ListArgs = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch,
    filter,
  };

  const {
    data: { data, errors },
  } = await makeGraphqlListRequest<{ users?: GraphQLListResponse<UserGET> }>(
    USERS_GRAPHQL_MODEL,
    fields || defaultFields,
    gqlArgs,
    preciseCount,
  );

  if (errors) {
    throw new Error(errors.map(({ message }) => message).join('; '));
  }

  if (!data.users?.edges || !data.users?.pageInfo) {
    throw new Error('Oops, fetching user list failed. Try again or contact administrator.'); // "edges" or "pageInfo" missing
  }

  const statsResponse = await getUserStats();
  const stats = statsResponse.map(({ active, count }) => ({
    count,
    category: active === 'true' ? UsersCategories.ACTIVE : UsersCategories.INACTIVE,
  }));

  const users = data.users.edges;
  const meta = {
    ...data.users.pageInfo,
    category,
    stats,
  };
  const normalizedUsers = users.map((user: UserGET) => normalizeUser(user));

  return {
    data: normalizedUsers,
    meta,
  };
};

export const useGetUsersRequest = (options?: Options<GetUsersResponse, GetUsersParams>) => {
  const dispatch = useDispatch();

  return useApiRequest(getUsers, {
    ...options,
    onSuccess: (data, params) => {
      data.data.forEach((user) => dispatch(updateLoggedInUser(user))); // try to update loggedInUser if it is present
      options?.onSuccess?.(data, params);
    },
  });
};

const getUserStats = async (): Promise<{ active: string; count: number }[]> => {
  const args: ListArgs = {
    group: { key: 'active', operation: GroupOperation.COUNT },
    filter: { deleted: { EQ: false } },
  };

  const {
    data: {
      data: { users },
      errors,
    },
  } = await makeGraphqlGroupRequest<{ users?: GraphQLGroupResponse }>(USERS_GRAPHQL_MODEL, args);

  if (errors) {
    throw new Error(errors.map(({ message }) => message).join('; '));
  }

  if (!users?.groupedEdges) {
    throw new Error('Unexpected error: "groupedEdges" missing.');
  }

  return users.groupedEdges.map(({ _id, value }) => ({
    active: String(_id),
    count: value,
  }));
};

type GetUser = (userId: string) => Promise<GetUserResponse>;
export type GetUserResponse = User;
export type GetUserParams = Parameters<GetUser>;
export const getUser: GetUser = async (userId) => {
  const {
    data: { data },
  } = await api.get<{ data: UserGET }>(`${USERS_ENDPOINT}/${userId}`);

  return normalizeUser(data);
};

export const useGetUserRequest = (options?: Options<GetUserResponse, GetUserParams>) => {
  const dispatch = useDispatch();

  return useApiRequest(getUser, {
    ...options,
    onSuccess: (data, params) => {
      dispatch(updateLoggedInUser(data));
      options?.onSuccess?.(data, params);
    },
  });
};

export const getProfilePictureByUserId = async (userId: string): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${USERS_ENDPOINT}/${userId}/profile`, { responseType: 'blob' });

  return data;
};

type ActivateUser = (userId: string) => Promise<ActivateUserResponse>;
export type ActivateUserResponse = void;
export type ActivateUserParams = Parameters<ActivateUser>;
export const activateUser: ActivateUser = async (userId) => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/activate`);
};

type DeactivateUser = (userId: string) => Promise<DeactivateUserResponse>;
export type DeactivateUserResponse = void;
export type DeactivateUserParams = Parameters<DeactivateUser>;
export const deactivateUser: DeactivateUser = async (userId) => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/deactivate`);
};

type DeleteUser = (userId: string) => Promise<DeleteUserResponse>;
export type DeleteUserResponse = boolean;
export type DeleteUserParams = Parameters<DeleteUser>;
export const deleteUser: DeleteUser = async (userId) => {
  await api.delete<unknown>(`${USERS_ENDPOINT}/${userId}`);

  return true;
};

type CreateUser = (user: UserPOST) => Promise<CreateUserResponse>;
export type CreateUserResponse = User;
export type CreateUserParams = Parameters<CreateUser>;
export const createUser: CreateUser = async (user) => {
  const {
    data: { data },
  } = await api.post<{ data: UserGET }>(USERS_ENDPOINT, user);

  return normalizeUser(data);
};

export const sendMessageToUserId = async (userId: string, message: string): Promise<boolean> => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/message`, { text: message });

  return true;
};

type UpdateUser = (userId: string, partialUser: UserPUT) => Promise<UpdateUserResponse>;
export type UpdateUserResponse = User;
export type UpdateUserParams = Parameters<UpdateUser>;
export const updateUser: UpdateUser = async (userId, partialUser) => {
  const {
    data: { data: user },
  } = await api.put<{ data: UserGET }>(`${USERS_ENDPOINT}/${userId}`, partialUser);

  return normalizeUser(user);
};

export const getListConfig = async (userId: string): Promise<ListConfigApi[]> => {
  const {
    data: { data },
  } = await api.get<{ data: ListConfigApi[] }>(`${USERS_ENDPOINT}/${userId}/list-config`);

  return data;
};

export const syncListConfig = async (userId: string, listConfigData: ListConfigApi): Promise<boolean> => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/list-config`, listConfigData);

  return true;
};

export const getUsersLicenseUsage = async (): Promise<LicenseUsageGET> => {
  const {
    data: { data },
  } = await api.get<{ data: LicenseUsageGET }>(`${USERS_ENDPOINT}/license-usage/`);

  return data;
};

export const getTestRunDataByFunction = async (userId: string, functionId): Promise<{ testRunData: string }> => {
  const {
    data: { data },
  } = await api.get<{ data: { testRunData: string } }>(`${USERS_ENDPOINT}/${userId}/test-run-data/${functionId}`);

  return data;
};

export const updateTestRunDataByFunction = async (
  userId: string,
  functionId: string,
  testRunData: string,
): Promise<boolean> => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/test-run-data/${functionId}`, { testRunData });

  return true;
};
