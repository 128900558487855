/* eslint-disable max-classes-per-file */
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import {
  Equals,
  IsBoolean,
  IsEnum,
  IsLatitude,
  IsLongitude,
  IsNumber,
  IsOptional,
  IsString,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import mongoose from 'mongoose';
import { LayoutOrientation } from '../../LayoutOrientation';
import { ComparingConditionOperator } from '../../RuleCondition/ConditionOperator';
import { DatabaseViewMapping } from '../DatabaseViewMapping';
import { ModelNames } from '../ModelNames';
import { ClassValidatorOptions } from '../utils/ClassValidatorOptions';
import { StepAction, StepActionBase, StepActionBaseSchema, StepActionDiscriminator } from './StepAction';
import { StepButton, StepButtonSchema } from './StepButton';
import { ImageStyles, TextStyles } from './Styles';

export enum WorkinstructionStepTypes {
  // INFO
  INFO_AUDIO = 'info-audio',
  INFO_PDF = 'info-pdf',
  INFO_PHOTO = 'info-photo',
  INFO_QRCODE = 'info-qrcode',
  INFO_STATUS = 'info-status',
  INFO_TEXT = 'info-text',
  INFO_TEXT_LARGE = 'info-text-large',
  INFO_LOCATION_DIRECTION = 'info-location-direction',
  INFO_WEBVIEW = 'info-webview',
  INFO_VIDEO = 'info-video',
  INFO_EMPTY = 'info-empty', // This step is used only when WI is an event
  INFO_LOADING = 'info-status-auto',
  INFO_TASK = 'info-task', // This is a special step used only on the frontend to manage task preview (on rule edit)
  // INPUT
  INPUT_AUDIO = 'input-audio',
  INPUT_BARCODE = 'input-barcode',
  INPUT_CHECKBOX_LIST = 'input-checkbox-list',
  INPUT_INDEX_LIST = 'input-index-list',
  INPUT_TILE_LIST = 'input-tile-list',
  INPUT_NUMBER = 'input-number',
  INPUT_NUMBER_PICKER = 'input-number-picker',
  INPUT_PHOTO = 'input-photo',
  INPUT_STEP_MENU = 'input-step-menu',
  INPUT_TEXT = 'input-text',
  INPUT_LOCATION = 'input-location',
  INPUT_NOISE_LEVEL = 'input-noise-level',
  INPUT_VALUE_LIST = 'input-value-list',
  INPUT_VIDEO = 'input-video',
  INPUT_BUTTON_LIST = 'input-button-list',
  // LAYOUT
  LAYOUT_ASSEMBLY_PICTURE = 'layout-assembly-picture',
  LAYOUT_ASSEMBLY_VIDEO = 'layout-assembly-video',
  LAYOUT_ASSEMBLY_DETAIL = 'layout-assembly-detail',
  LAYOUT_SCREW_FITTING = 'layout-screw-fitting',
  LAYOUT_FLEX = 'layout-flex',
}

export const INFO_EMPTY_ID = 'infoempty';

export enum InputBarcodeRegexType {
  INCLUDE = '+',
}

export enum ListOptionsSource {
  CONNECTOR = 'connector',
  DATABASE = 'database',
  OPTIONS = 'options',
  VARIABLE = 'variables',
}

export const InputBarcodeOperators = [
  ComparingConditionOperator.EQUAL,
  ComparingConditionOperator.CONTAINS,
  ComparingConditionOperator.BEGINS,
  ComparingConditionOperator.ENDS,
  ComparingConditionOperator.EQUALS_VAR,
  ComparingConditionOperator.REGEX,
] as const;

@Schema({ _id: false })
export class StepTimeout {
  @Prop()
  @IsNumber()
  seconds: number;

  @Prop({ type: StepActionBaseSchema, discriminators: StepActionDiscriminator })
  @ValidateNested()
  @Type(() => StepActionBase)
  @ValidateIf((object, value) => value !== null)
  action: StepAction | null;
}

@Schema({ _id: false })
export class StepStyles {
  @Prop()
  @IsString()
  @IsOptional()
  backgroundColor?: string;

  @Prop({ type: SchemaFactory.createForClass(TextStyles) })
  @IsOptional()
  @ValidateNested()
  @Type(() => TextStyles)
  title?: TextStyles;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Schema({ discriminatorKey: 'type', _id: false, overwriteModels: true })
export class StepBase {
  _id: string;

  @Prop({ required: true })
  @IsString()
  id: string;

  @Prop({
    type: String,
    enum: Object.values(WorkinstructionStepTypes),
    required: true,
    $skipDiscriminatorCheck: true,
  })
  @IsEnum(WorkinstructionStepTypes, ClassValidatorOptions.getEnumErrorMessageOptions(WorkinstructionStepTypes))
  type: WorkinstructionStepTypes;

  @Prop()
  @IsString()
  title: string;

  @Prop({ default: false })
  @IsBoolean()
  @IsOptional()
  hideTitleBar?: boolean;

  @Prop([{ type: StepButtonSchema }])
  @ValidateNested({ each: true })
  @Type(() => StepButton)
  buttons: StepButton[];

  @Prop()
  @IsNumber()
  @IsOptional()
  maxTime?: number;

  @Prop({ type: SchemaFactory.createForClass(StepTimeout) })
  @ValidateNested()
  @IsOptional()
  @Type(() => StepTimeout)
  timeout?: StepTimeout;

  @Prop()
  @IsString()
  @IsOptional()
  loadVariablesFromUrl?: string;

  @Prop()
  @IsNumber()
  @IsOptional()
  index?: number;

  @Prop({ default: false })
  @IsOptional()
  @IsBoolean()
  showAsBox?: boolean;

  @Prop({ type: SchemaFactory.createForClass(StepStyles) })
  styles?: StepStyles;
}

export const StepBaseSchema = SchemaFactory.createForClass(StepBase);

@Schema()
export class InfoText extends StepBase {
  type: WorkinstructionStepTypes.INFO_TEXT;

  @Prop()
  @IsString()
  description: string;
}

@Schema()
export class InfoTextLarge extends StepBase {
  type: WorkinstructionStepTypes.INFO_TEXT_LARGE;

  @Prop()
  @IsString()
  description: string;
}

@Schema()
export class InfoWebview extends StepBase {
  type: WorkinstructionStepTypes.INFO_WEBVIEW;

  @Prop()
  @IsString()
  url?: string;
}

@Schema()
class InfoMediaStepBase extends StepBase {
  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ required: true })
  @IsString()
  mediaId: string;
}
@Schema()
export class InfoAudio extends InfoMediaStepBase {
  type: WorkinstructionStepTypes.INFO_AUDIO;
}

@Schema()
export class InfoVideo extends InfoMediaStepBase {
  type: WorkinstructionStepTypes.INFO_VIDEO;
}

@Schema({ _id: false })
export class InfoPhotoStyles extends StepStyles {
  @Prop({ type: SchemaFactory.createForClass(ImageStyles) })
  @ValidateNested()
  @Type(() => ImageStyles)
  image: ImageStyles;
}

@Schema()
export class InfoPhoto extends InfoMediaStepBase {
  type: WorkinstructionStepTypes.INFO_PHOTO;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsBoolean()
  @IsOptional()
  hideZoomButton?: boolean;

  @Prop({ type: SchemaFactory.createForClass(InfoPhotoStyles) })
  @ValidateNested()
  @Type(() => InfoPhotoStyles)
  styles: InfoPhotoStyles;
}

@Schema()
export class InfoPdf extends InfoMediaStepBase {
  type: WorkinstructionStepTypes.INFO_PDF;

  @Prop({
    required: true,
    default: '0',
  })
  @IsString()
  page: string;

  @Prop()
  @IsString()
  description: string;

  @Prop({
    default: false,
  })
  @IsBoolean()
  slideModeEnabled: boolean;
}

@Schema()
export class InfoLoading extends StepBase {
  type: WorkinstructionStepTypes.INFO_LOADING;

  @Prop({
    required: true,
    default: '0',
  })
  @IsString()
  taskAppearingDuration: number;
}

@Schema()
export class InfoStatus extends StepBase {
  type: WorkinstructionStepTypes.INFO_STATUS;

  @Prop({
    required: true,
  })
  @IsString()
  color: string;

  @Prop({
    required: true,
  })
  @IsString()
  icon: string;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsString()
  @IsOptional()
  headline?: string;
}

@Schema()
export class InfoLocationDirection extends StepBase {
  type: WorkinstructionStepTypes.INFO_LOCATION_DIRECTION;

  @Prop()
  @IsString()
  description: string;

  @Prop({
    trim: true,
    required: true,
  })
  @IsLatitude()
  gpsLocationLatitude: string;

  @Prop({
    trim: true,
    required: true,
  })
  @IsLongitude()
  gpsLocationLongitude: string;
}

@Schema()
export class InfoQrCode extends StepBase {
  type: WorkinstructionStepTypes.INFO_QRCODE;

  @Prop({
    required: true,
  })
  @IsString()
  qrcode: string;
}

@Schema()
export class InfoEmpty extends StepBase {
  type: WorkinstructionStepTypes.INFO_EMPTY;

  @Prop({
    required: true,
    type: String,
    enum: [INFO_EMPTY_ID],
    default: INFO_EMPTY_ID,
  })
  @Equals(INFO_EMPTY_ID)
  id: typeof INFO_EMPTY_ID;
}

@Schema()
export class InfoTask extends StepBase {
  type: WorkinstructionStepTypes.INFO_TASK;

  @Prop()
  @Equals('task')
  id: 'task';

  @Prop()
  @IsString()
  taskTitle: string;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsString()
  priority: string;
}

/**
 * Only used for input-* steps
 * */
@Schema()
export class InputableStep extends StepBase {
  @Prop({ required: true })
  @IsString()
  outputVarName: string;
}

@Schema()
export class InputAudio extends InputableStep {
  type: WorkinstructionStepTypes.INPUT_AUDIO;
}

@Schema()
export class InputLocation extends InputableStep {
  type: WorkinstructionStepTypes.INPUT_LOCATION;

  @Prop()
  @IsString()
  description: string;

  @Prop({ trim: true, required: true })
  @IsNumber()
  minAccuracyInMeters: number;
}

@Schema()
export class InputText extends InputableStep {
  type: WorkinstructionStepTypes.INPUT_TEXT;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsString()
  @IsOptional()
  placeholder?: string;

  @Prop()
  @IsString()
  @IsOptional()
  maxCharacters?: number;

  @Prop()
  @IsBoolean()
  @IsOptional()
  isOptional?: boolean;
}

@Schema()
export class InputBarcodeRegex {
  @Prop()
  @IsString()
  id: string;

  @Prop({
    type: String,
    enum: Object.values(InputBarcodeRegexType),
    default: InputBarcodeRegexType.INCLUDE,
  })
  @IsEnum(InputBarcodeRegexType, ClassValidatorOptions.getEnumErrorMessageOptions(InputBarcodeRegexType))
  type: InputBarcodeRegexType;

  @Prop()
  @IsString()
  value: string;

  @Prop({ enum: InputBarcodeOperators, type: String })
  @IsEnum(InputBarcodeOperators, ClassValidatorOptions.getEnumErrorMessageOptions(InputBarcodeOperators))
  operator: (typeof InputBarcodeOperators)[number];
}

@Schema()
export class InputBarcode extends InputableStep {
  type: WorkinstructionStepTypes.INPUT_BARCODE;

  @Prop([{ type: SchemaFactory.createForClass(InputBarcodeRegex) }])
  @ValidateNested({ each: true })
  @Type(() => InputBarcodeRegex)
  regexOp: InputBarcodeRegex[];

  @Prop()
  @IsString({ each: true })
  regex: string[];

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop({ default: false })
  @IsBoolean()
  allowManualBarcodeInput: boolean;
}

@Schema()
export class InputNumberBase extends InputableStep {
  @Prop()
  @IsNumber()
  integerDigits: number;

  @Prop()
  @IsNumber()
  decimalDigits: number;

  @Prop({ type: String })
  @IsString()
  @IsOptional()
  defaultValueDecimal?: string; // string representation of number value (eg '12.35') or a variable name (eg. '$varName)
}

@Schema()
export class InputNumber extends InputNumberBase {
  type: WorkinstructionStepTypes.INPUT_NUMBER;
}

@Schema()
export class InputNumberPicker extends InputNumberBase {
  type: WorkinstructionStepTypes.INPUT_NUMBER_PICKER;

  @Prop()
  @IsString()
  description: string;
}

@Schema()
export class InputPhoto extends InputableStep {
  type: WorkinstructionStepTypes.INPUT_PHOTO;
}

@Schema()
export class InputVideo extends InputableStep {
  type: WorkinstructionStepTypes.INPUT_VIDEO;
}

@Schema({ _id: false })
export class InputListBaseOption {
  @Prop()
  @IsString()
  id: string;

  @Prop()
  @IsString()
  text: string;

  @Prop({ type: StepActionBaseSchema })
  @ValidateNested()
  @Type(() => StepActionBase)
  @ValidateIf((object, value) => value !== null)
  action: StepAction | null;
}

@Schema({ _id: false })
export class InputListOption extends InputListBaseOption {
  @Prop()
  @IsString()
  @IsOptional()
  regex?: string;

  @Prop()
  @IsString()
  @IsOptional()
  icon?: string;

  @Prop()
  @IsString()
  @IsOptional()
  title?: string;
}

export type ListOptions<T extends InputListBaseOption> = T[] | string /* variable name */;

@Schema()
export class OptionsList<T extends InputListBaseOption> {
  @Prop({
    type: String,
    enum: Object.values(ListOptionsSource),
    default: ListOptionsSource.OPTIONS,
  })
  @IsEnum(ListOptionsSource, ClassValidatorOptions.getEnumErrorMessageOptions(ListOptionsSource))
  source: ListOptionsSource;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<T>;

  @Prop({
    type: mongoose.Schema.Types.ObjectId,
    ref: ModelNames.DatabaseViewMapping,
  })
  @IsString()
  @IsOptional() // used only if source === ListOptionsSource.DATABASE
  mapping?: string;

  /** @deprecated - use source as a discriminator key */
  @Prop()
  @IsBoolean()
  isVar: boolean;

  /** @deprecated - use source as a discriminator key */
  @Prop()
  @IsBoolean()
  loadFromUrl: boolean;

  @Prop({ type: String })
  @IsString()
  @IsOptional()
  loadOptionsFromUrl?: string;
}

@Schema()
export class InputListBaseStep extends InputableStep {
  @Prop()
  @IsNumber()
  minSelect: number;

  @Prop({
    type: String,
    enum: Object.values(ListOptionsSource),
    default: ListOptionsSource.OPTIONS,
  })
  @IsEnum(ListOptionsSource, ClassValidatorOptions.getEnumErrorMessageOptions(ListOptionsSource))
  source: ListOptionsSource;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop()
  @IsBoolean()
  loadFromUrl: boolean;

  @Prop({ type: String })
  @IsString()
  @IsOptional()
  loadOptionsFromUrl?: string;

  @Prop({
    type: mongoose.Schema.Types.ObjectId,
    ref: ModelNames.DatabaseViewMapping,
  })
  @IsString()
  @IsOptional() // used only if source === ListOptionsSource.DATABASE
  mapping?: string | DatabaseViewMapping;
}
@Schema()
export class InputListSearchableStep extends InputListBaseStep {
  @Prop({ default: false })
  @IsBoolean()
  showSearchBar: boolean;
}

@Schema({ _id: false })
export class InputIndexListOption extends InputListOption {
  @Prop()
  @IsOptional()
  @IsBoolean()
  isHeadline?: boolean;
}

@Schema()
export class InputIndexList extends InputListSearchableStep {
  type: WorkinstructionStepTypes.INPUT_INDEX_LIST;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputIndexListOption>;
}

@Schema({ _id: false })
export class InputTileListOption extends InputListBaseOption {}

@Schema()
export class InputTileList extends InputListBaseStep {
  type: WorkinstructionStepTypes.INPUT_TILE_LIST;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputTileListOption>;

  @Prop({ default: true })
  @IsBoolean()
  autoContinue: boolean;

  @Prop()
  @IsBoolean()
  customNextStep: boolean;
}

@Schema({ _id: false })
export class InputValueListOption extends InputListOption {}

@Schema()
export class InputValueList extends InputListSearchableStep {
  type: WorkinstructionStepTypes.INPUT_VALUE_LIST;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop()
  @IsBoolean()
  customNextStep: boolean;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputValueListOption>;
}

@Schema({ _id: false })
export class InputCheckboxListOption extends InputListOption {
  @Prop()
  @IsBoolean()
  @IsOptional()
  isChecked?: boolean;
}

@Schema()
export class InputCheckboxList extends InputListSearchableStep {
  type: WorkinstructionStepTypes.INPUT_CHECKBOX_LIST;

  @Prop()
  @IsString()
  @IsOptional()
  refresh?: string;

  @Prop()
  @IsBoolean()
  selectAll: boolean;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputCheckboxListOption>;
}

@Schema({ _id: false })
export class InputButtonListOption extends InputValueListOption {}

@Schema()
export class InputButtonList extends InputListBaseStep {
  type: WorkinstructionStepTypes.INPUT_BUTTON_LIST;

  @Prop()
  @IsString()
  description: string;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputButtonListOption>;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop()
  @IsString()
  @IsOptional()
  imageMediaId?: string;

  @Prop()
  @IsBoolean()
  @IsOptional()
  imageMediaIdIsVar?: boolean;
}

@Schema({ _id: false })
export class InputStepMenuOption extends InputListOption {}

@Schema()
export class InputStepMenu extends InputableStep {
  type: WorkinstructionStepTypes.INPUT_STEP_MENU;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop({
    type: String,
    enum: [ListOptionsSource.OPTIONS],
    default: ListOptionsSource.OPTIONS,
  })
  @IsEnum(ListOptionsSource, ClassValidatorOptions.getEnumErrorMessageOptions(ListOptionsSource))
  source: ListOptionsSource;

  @Prop([{ type: InputStepMenuOption }])
  @ValidateNested()
  options: InputStepMenuOption[];
}

@Schema()
export class InputNoiseLevel extends InputableStep {
  type: WorkinstructionStepTypes.INPUT_NOISE_LEVEL;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop()
  @IsBoolean()
  autoStartMeasurement: boolean;

  @Prop()
  @IsBoolean()
  inputRequired: boolean;

  @Prop()
  @IsNumber()
  warningThresholdInDecibel: number;

  @Prop()
  @IsNumber()
  measureDurationInSec: number;
}

export enum FlexDirection {
  ROW = 'row',
  COLUMN = 'column',
}

export enum FlexChildType {
  STEP = 'step',
  FLEX = 'flex',
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Schema({ _id: false, discriminatorKey: 'type', overwriteModels: true })
class FlexChildBase {
  @Prop()
  flex: number;

  @Prop({ type: String, enum: Object.values(FlexChildType), required: true, $skipDiscriminatorCheck: true })
  type: FlexChildType;
}

// Should include only info and input steps
const FlexStepDiscriminators: {
  [stepType in Exclude<
    WorkinstructionStepTypes,
    | WorkinstructionStepTypes.LAYOUT_FLEX
    | WorkinstructionStepTypes.LAYOUT_ASSEMBLY_PICTURE
    | WorkinstructionStepTypes.LAYOUT_ASSEMBLY_VIDEO
    | WorkinstructionStepTypes.LAYOUT_ASSEMBLY_DETAIL
    | WorkinstructionStepTypes.LAYOUT_SCREW_FITTING
  >]: mongoose.Schema;
} = {
  [WorkinstructionStepTypes.INFO_WEBVIEW]: SchemaFactory.createForClass(InfoWebview),
  [WorkinstructionStepTypes.INFO_TEXT]: SchemaFactory.createForClass(InfoText),
  [WorkinstructionStepTypes.INFO_TEXT_LARGE]: SchemaFactory.createForClass(InfoTextLarge),
  [WorkinstructionStepTypes.INFO_AUDIO]: SchemaFactory.createForClass(InfoAudio),
  [WorkinstructionStepTypes.INFO_VIDEO]: SchemaFactory.createForClass(InfoVideo),
  [WorkinstructionStepTypes.INFO_PHOTO]: SchemaFactory.createForClass(InfoPhoto),
  [WorkinstructionStepTypes.INFO_PDF]: SchemaFactory.createForClass(InfoPdf),
  [WorkinstructionStepTypes.INFO_LOADING]: SchemaFactory.createForClass(InfoLoading),
  [WorkinstructionStepTypes.INFO_STATUS]: SchemaFactory.createForClass(InfoStatus),
  [WorkinstructionStepTypes.INFO_QRCODE]: SchemaFactory.createForClass(InfoQrCode),
  [WorkinstructionStepTypes.INFO_EMPTY]: SchemaFactory.createForClass(InfoEmpty),
  [WorkinstructionStepTypes.INFO_TASK]: SchemaFactory.createForClass(InfoTask),
  [WorkinstructionStepTypes.INPUT_AUDIO]: SchemaFactory.createForClass(InputAudio),
  [WorkinstructionStepTypes.INPUT_TEXT]: SchemaFactory.createForClass(InputText),
  [WorkinstructionStepTypes.INPUT_LOCATION]: SchemaFactory.createForClass(InputLocation),
  [WorkinstructionStepTypes.INFO_LOCATION_DIRECTION]: SchemaFactory.createForClass(InfoLocationDirection),
  [WorkinstructionStepTypes.INPUT_BARCODE]: SchemaFactory.createForClass(InputBarcode),
  [WorkinstructionStepTypes.INPUT_NUMBER]: SchemaFactory.createForClass(InputNumber),
  [WorkinstructionStepTypes.INPUT_NUMBER_PICKER]: SchemaFactory.createForClass(InputNumberPicker),
  [WorkinstructionStepTypes.INPUT_PHOTO]: SchemaFactory.createForClass(InputPhoto),
  [WorkinstructionStepTypes.INPUT_VIDEO]: SchemaFactory.createForClass(InputVideo),
  [WorkinstructionStepTypes.INPUT_VALUE_LIST]: SchemaFactory.createForClass(InputValueList),
  [WorkinstructionStepTypes.INPUT_CHECKBOX_LIST]: SchemaFactory.createForClass(InputCheckboxList),
  [WorkinstructionStepTypes.INPUT_INDEX_LIST]: SchemaFactory.createForClass(InputIndexList),
  [WorkinstructionStepTypes.INPUT_TILE_LIST]: SchemaFactory.createForClass(InputTileList),
  [WorkinstructionStepTypes.INPUT_BUTTON_LIST]: SchemaFactory.createForClass(InputButtonList),
  [WorkinstructionStepTypes.INPUT_STEP_MENU]: SchemaFactory.createForClass(InputStepMenu),
  [WorkinstructionStepTypes.INPUT_NOISE_LEVEL]: SchemaFactory.createForClass(InputNoiseLevel),
};

@Schema({ _id: false })
export class FlexChildStep extends FlexChildBase {
  type: FlexChildType.STEP;

  @Prop({
    type: StepBaseSchema,
    discriminators: FlexStepDiscriminators,
  })
  step: Exclude<Step, LayoutFlexStep>; // Only info and input steps
}

@Schema({ _id: false })
export class FlexChildFlex extends FlexChildBase {
  type: FlexChildType.FLEX;

  @Prop({ type: String, enum: Object.values(FlexDirection) })
  direction: FlexDirection;

  @Prop([
    { type: Object }, // FlexChild discriminators won't work here because of recursive dependency
  ])
  children: FlexChild[];
}

export type FlexChild = FlexChildStep | FlexChildFlex;

@Schema()
export class LayoutFlexStep extends StepBase {
  type: WorkinstructionStepTypes.LAYOUT_FLEX;

  @Prop({ type: String, enum: Object.values(FlexDirection) })
  direction: FlexDirection;

  @Prop([
    { type: Object }, // FlexChild discriminators won't work here because of recursive dependency
  ])
  children: FlexChild[];
}

@Schema()
export class LayoutAssemblyPicture extends StepBase {
  type: WorkinstructionStepTypes.LAYOUT_ASSEMBLY_PICTURE;

  @Prop()
  @IsString()
  description: string;

  @Prop({ required: true })
  @IsString()
  mediaId: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ type: SchemaFactory.createForClass(InfoPhotoStyles) })
  @ValidateNested()
  @Type(() => InfoPhotoStyles)
  styles: InfoPhotoStyles;

  @Prop({ type: String, enum: Object.values(LayoutOrientation) })
  @IsEnum(LayoutOrientation, ClassValidatorOptions.getEnumErrorMessageOptions(LayoutOrientation))
  orientation: LayoutOrientation;
}

@Schema()
export class LayoutAssemblyVideo extends StepBase {
  type: WorkinstructionStepTypes.LAYOUT_ASSEMBLY_VIDEO;

  @Prop({ type: String, enum: Object.values(LayoutOrientation) })
  @IsEnum(LayoutOrientation, ClassValidatorOptions.getEnumErrorMessageOptions(LayoutOrientation))
  orientation: LayoutOrientation;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ required: true })
  @IsString()
  mediaId: string;
}

@Schema({ _id: false })
export class MaterialsList extends OptionsList<InputValueListOption> {
  @Prop()
  @IsBoolean()
  @IsOptional()
  autoContinue?: boolean;

  @Prop()
  @IsBoolean()
  @IsOptional()
  customNextStep?: boolean;
}

@Schema()
export class LayoutAssemblyDetail extends StepBase {
  type: WorkinstructionStepTypes.LAYOUT_ASSEMBLY_DETAIL;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ required: true })
  @IsString()
  mediaId: string;

  @Prop({ default: false })
  @IsBoolean()
  showSearchBar: boolean;

  @Prop({ type: SchemaFactory.createForClass(InfoPhotoStyles) })
  @ValidateNested()
  @Type(() => InfoPhotoStyles)
  styles: InfoPhotoStyles;

  @Prop({ type: SchemaFactory.createForClass(MaterialsList) })
  @ValidateNested()
  @Type(() => MaterialsList)
  materialsList: MaterialsList;
}

@Schema({ _id: false })
export class LayoutScrewFittingBit {
  @Prop()
  @IsString()
  mediaId: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;
}
@Schema()
export class LayoutScrewFitting extends StepBase {
  type: WorkinstructionStepTypes.LAYOUT_SCREW_FITTING;

  @Prop({ required: true })
  @IsString()
  mediaId: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ default: false })
  @IsBoolean()
  showSearchBar: boolean;

  @Prop({ type: SchemaFactory.createForClass(InfoPhotoStyles) })
  @ValidateNested()
  @Type(() => InfoPhotoStyles)
  styles: InfoPhotoStyles;

  @Prop()
  @IsString()
  description: string;

  @Prop({ type: SchemaFactory.createForClass(MaterialsList) })
  @ValidateNested()
  @Type(() => MaterialsList)
  materialsList: MaterialsList;

  @Prop({ type: SchemaFactory.createForClass(LayoutScrewFittingBit) })
  @IsOptional()
  @ValidateNested()
  @Type(() => LayoutScrewFittingBit)
  bit?: LayoutScrewFittingBit;

  @Prop()
  @IsString()
  count: string; // string representation of integer number value (eg '12') or a variable name (eg. '$varName)

  @Prop()
  @IsString()
  torque: string; // string representation of integer number value (eg '12') or a variable name (eg. '$varName)
}

export type Step =
  | InfoWebview
  | InfoText
  | InfoTextLarge
  | InfoAudio
  | InfoVideo
  | InfoPhoto
  | InfoPdf
  | InfoLoading
  | InfoStatus
  | InfoQrCode
  | InfoEmpty
  | InfoTask
  | InputAudio
  | InputText
  | InputLocation
  | InfoLocationDirection
  | InputBarcode
  | InputNumber
  | InputNumberPicker
  | InputPhoto
  | InputVideo
  | InputValueList
  | InputCheckboxList
  | InputIndexList
  | InputTileList
  | InputButtonList
  | InputStepMenu
  | InputNoiseLevel
  | LayoutAssemblyPicture
  | LayoutAssemblyVideo
  | LayoutAssemblyDetail
  | LayoutScrewFitting
  | LayoutFlexStep;

export const StepClassByStepType: {
  [key in WorkinstructionStepTypes]: any;
} = {
  [WorkinstructionStepTypes.INFO_WEBVIEW]: InfoWebview,
  [WorkinstructionStepTypes.INFO_TEXT]: InfoText,
  [WorkinstructionStepTypes.INFO_TEXT_LARGE]: InfoTextLarge,
  [WorkinstructionStepTypes.INFO_AUDIO]: InfoAudio,
  [WorkinstructionStepTypes.INFO_VIDEO]: InfoVideo,
  [WorkinstructionStepTypes.INFO_PHOTO]: InfoPhoto,
  [WorkinstructionStepTypes.INFO_PDF]: InfoPdf,
  [WorkinstructionStepTypes.INFO_LOADING]: InfoLoading,
  [WorkinstructionStepTypes.INFO_STATUS]: InfoStatus,
  [WorkinstructionStepTypes.INFO_QRCODE]: InfoQrCode,
  [WorkinstructionStepTypes.INFO_EMPTY]: InfoEmpty,
  [WorkinstructionStepTypes.INFO_TASK]: InfoTask,
  [WorkinstructionStepTypes.INPUT_AUDIO]: InputAudio,
  [WorkinstructionStepTypes.INPUT_TEXT]: InputText,
  [WorkinstructionStepTypes.INPUT_LOCATION]: InputLocation,
  [WorkinstructionStepTypes.INFO_LOCATION_DIRECTION]: InfoLocationDirection,
  [WorkinstructionStepTypes.INPUT_BARCODE]: InputBarcode,
  [WorkinstructionStepTypes.INPUT_NUMBER]: InputNumber,
  [WorkinstructionStepTypes.INPUT_NUMBER_PICKER]: InputNumberPicker,
  [WorkinstructionStepTypes.INPUT_PHOTO]: InputPhoto,
  [WorkinstructionStepTypes.INPUT_VIDEO]: InputVideo,
  [WorkinstructionStepTypes.INPUT_VALUE_LIST]: InputValueList,
  [WorkinstructionStepTypes.INPUT_CHECKBOX_LIST]: InputCheckboxList,
  [WorkinstructionStepTypes.INPUT_INDEX_LIST]: InputIndexList,
  [WorkinstructionStepTypes.INPUT_TILE_LIST]: InputTileList,
  [WorkinstructionStepTypes.INPUT_BUTTON_LIST]: InputButtonList,
  [WorkinstructionStepTypes.INPUT_STEP_MENU]: InputStepMenu,
  [WorkinstructionStepTypes.INPUT_NOISE_LEVEL]: InputNoiseLevel,
  [WorkinstructionStepTypes.LAYOUT_ASSEMBLY_PICTURE]: LayoutAssemblyPicture,
  [WorkinstructionStepTypes.LAYOUT_ASSEMBLY_VIDEO]: LayoutAssemblyVideo,
  [WorkinstructionStepTypes.LAYOUT_ASSEMBLY_DETAIL]: LayoutAssemblyDetail,
  [WorkinstructionStepTypes.LAYOUT_SCREW_FITTING]: LayoutScrewFitting,
  [WorkinstructionStepTypes.LAYOUT_FLEX]: LayoutFlexStep,
};

export const StepsDiscriminators: {
  [key in WorkinstructionStepTypes]: mongoose.Schema;
} = Object.keys(StepClassByStepType).reduce(
  (acc, stepType) => {
    acc[stepType] = SchemaFactory.createForClass(StepClassByStepType[stepType]);
    return acc;
  },
  {} as {
    [key in WorkinstructionStepTypes]: mongoose.Schema;
  },
);
