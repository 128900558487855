import { LoginPOSTResponse } from '@workerbase/types/api/auth';
import { LoggedUser, User } from 'services/types/User';

import { BaseAction } from '../common/actions';

export enum LoginActions {
  LOGIN_USER_REQUEST = '@@login/LOGIN_USER_REQUEST',
  LOGIN_SSO_SUCCESS = '@@login/LOGIN_SSO_SUCCESS',
  LOGIN_SSO_FAILURE = '@@login/LOGIN_SSO_FAILURE',
  LOGIN_USER_SUCCESS = '@@login/LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE = '@@login/LOGIN_USER_FAILURE',
  LOGIN_USER_UPDATE_DATA = '@@login/LOGIN_USER_UPDATE_DATA',
  UPDATE_LOGGED_IN_USER = '@@login/UPDATE_LOGGED_IN_USER',
  LOGOUT_USER = '@@login/LOGOUT_USER',
  LOGOUT_USER_DELAYED = '@@login/LOGOUT_USER_DELAYED',
  SET_IS_LOADING_INITIAL_LIST_CONFIG = '@@login/LOADING_INITIAL_LIST_CONFIG',
}

export const loginUserRequestAction = (email: string, password: string): BaseAction => ({
  type: LoginActions.LOGIN_USER_REQUEST,
  payload: {
    email,
    password,
  },
});

export const loginSSORequestSuccess = (data: LoginPOSTResponse): BaseAction => ({
  type: LoginActions.LOGIN_SSO_SUCCESS,
  payload: data,
});

export const loginSSORequestFailure = (errorMessage: string | null): BaseAction => ({
  type: LoginActions.LOGIN_SSO_FAILURE,
  payload: { errorMessage },
});

export const loginUserRequestSuccess = (data: LoginPOSTResponse): BaseAction => ({
  type: LoginActions.LOGIN_USER_SUCCESS,
  payload: data,
});

export const loginUserUpdateData = (
  user: Omit<LoggedUser, 'isWorkbenchEnabled' | 'isOnPremise' | 'isMyWorkEnabled'>,
) => ({
  type: LoginActions.LOGIN_USER_UPDATE_DATA,
  payload: {
    user,
  },
});

export const loginUserRequestError = (errorMessage: string): BaseAction => ({
  type: LoginActions.LOGIN_USER_FAILURE,
  payload: {
    errorMessage,
  },
});

export const logoutUser = (): BaseAction => ({
  type: LoginActions.LOGOUT_USER,
});

export const logoutUserDelayed = (delayInMs: number): BaseAction => ({
  type: LoginActions.LOGOUT_USER_DELAYED,
  payload: {
    delayInMs,
  },
});

export const updateLoggedInUser = (user: User): BaseAction => ({
  type: LoginActions.UPDATE_LOGGED_IN_USER,
  payload: {
    user,
  },
});

export const setIsLoadingInitialListConfig = (isLoading: boolean): BaseAction => ({
  type: LoginActions.SET_IS_LOADING_INITIAL_LIST_CONFIG,
  payload: isLoading,
});
