import { push } from 'connected-react-router';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { LoginPOSTResponse } from '@workerbase/types/api/auth';
import { makeLoginRequest } from 'services/networking/auth';
import { LoggedUser, User } from 'services/types/User';

import { mqttLogout } from '@redux/Mqtt/actions';

import { getListConfigSaga } from '@redux/common/ListConfig/sagas';
import { LOGIN_TOKEN_LOCAL_STORAGE_KEY } from '../../globals';
import { Routes } from '../../routes';

import {
  LoginActions,
  setIsLoadingInitialListConfig,
  loginUserRequestError,
  loginUserRequestSuccess,
  loginUserUpdateData,
  logoutUser,
  logoutUserDelayed,
} from './actions';

import { getUser } from './selectors';

export function* loginSSOSaga(action) {
  try {
    localStorage.setItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY, action.payload.loginToken);
    yield put(loginUserRequestSuccess(action.payload));
    yield put(setIsLoadingInitialListConfig(true));
    yield call(getListConfigSaga, { payload: { userId: action.payload.user._id } });
    yield put(setIsLoadingInitialListConfig(false));
    yield put(push(Routes.Projects));
    yield put(logoutUserDelayed(action.payload.tokenTTLInMs));
  } catch (error) {
    yield put(loginUserRequestError((error as Error).message));
  }
}
export function* loginUserRequestSaga(action) {
  try {
    const loginData: LoginPOSTResponse = yield call(makeLoginRequest, action.payload);
    yield put(loginUserRequestSuccess(loginData));
    yield put(setIsLoadingInitialListConfig(true));
    yield call(getListConfigSaga, { payload: { userId: loginData.user._id } });
    yield put(setIsLoadingInitialListConfig(false));
    yield put(push(Routes.Projects));
    yield put(logoutUserDelayed(loginData.tokenTTLInMs));
  } catch (error) {
    yield put(loginUserRequestError((error as Error).message));
  }
}

export function* logoutUserSaga(action) {
  yield put(push(Routes.Login));
  yield put(mqttLogout());
}

export function* logoutUserDelayedSaga(action) {
  yield delay(action.payload.delayInMs - action.payload.delayInMs * 0.05); // Decreasing the time by 5% as suggested https://git.workerbase.io/workerbase/wb-backend/-/merge_requests/2310#note_62042
  yield put(logoutUser());
}

// When we update the logged in user, we also want to update the data of this user
// in the "Login" redux store.
// E.g. if logged in user change language, we want to update the language without
// forcing user to log out and log in again (so it updates its data in the Login redux store)
export function* updateLoggedInUserData(action) {
  const fetchedUser = action.payload.user as User;
  const loggedUser: LoggedUser | null = yield select(getUser);

  if (loggedUser === null) {
    return;
  }

  if (fetchedUser.id !== loggedUser.id) {
    return;
  }

  const updateUserDetails: Omit<LoggedUser, 'isWorkbenchEnabled' | 'isOnPremise' | 'isMyWorkEnabled'> = {
    id: fetchedUser.id,
    firstName: fetchedUser.firstName,
    lastName: fetchedUser.language,
    language: fetchedUser.language,
    email: fetchedUser.email,
    disableDeviceLock: fetchedUser.disableDeviceLock,
    isRootAdministrator: fetchedUser.isRootAdministrator,
    isDeveloper: fetchedUser.isDeveloper,
    roleIds: fetchedUser.rolesIds,
  };

  yield put(loginUserUpdateData(updateUserDetails));
}

export default function* loginSagas() {
  yield takeLatest(LoginActions.LOGIN_SSO_SUCCESS, loginSSOSaga);
  yield takeLatest(LoginActions.LOGIN_USER_REQUEST, loginUserRequestSaga);
  yield takeLatest(LoginActions.LOGOUT_USER, logoutUserSaga);
  yield takeLatest(LoginActions.LOGOUT_USER_DELAYED, logoutUserDelayedSaga);
  yield takeLatest(LoginActions.UPDATE_LOGGED_IN_USER, updateLoggedInUserData);
}
