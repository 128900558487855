import { Workinstruction, WorkinstructionOld } from '@workerbase/types/Workinstruction';

export abstract class WorkinstructionStepsMapper {
  static arrayToMap(workinstruction: Workinstruction): WorkinstructionOld {
    const workinstructionWithStepsMap = workinstruction as unknown as WorkinstructionOld;

    workinstructionWithStepsMap.steps = (workinstruction?.steps || []).reduce((stepsMap, step) => {
      stepsMap[step.id] = step;
      return stepsMap;
    }, {});

    return workinstructionWithStepsMap;
  }

  static mapToArray(workinstruction: WorkinstructionOld): Workinstruction {
    const workinstructionWithStepsArray = workinstruction as unknown as Workinstruction;

    workinstructionWithStepsArray.steps = Object.values(workinstruction?.steps || {});

    return workinstructionWithStepsArray;
  }
}
