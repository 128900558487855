// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarList-module__sidebarList___Fhrje{margin:0;padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarList": `SidebarList-module__sidebarList___Fhrje`
};
export default ___CSS_LOADER_EXPORT___;
