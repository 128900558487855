import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';

import PageLoader from 'components/PageLoader';
import LoggedInRoute from 'components/LoggedInRoute';
import LoggedOutRoute from 'components/LoggedOutRoute';
import { LandingSSO } from 'components/LandingSSO';

const Workbench = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "workbench" */ './pages/Workbench'));

const Login = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "login" */ './pages/Login'));
const Logout = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "logout" */ './pages/Logout'));
const ResetPassword = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "resetpassword" */ './pages/ResetPassword'),
);
const ResetPasswordNewPassword = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "resetpasswordnew" */ './pages/ResetPasswordNewPassword'),
);

// DEVICES
const DevicesList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "devicesList" */ './pages/Devices/DevicesList'),
);

// USERS
const UsersList = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "users" */ './pages/Users/UsersList'));
const UserEdit = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "users" */ './pages/Users/UserEdit'));
const UserCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "users" */ './pages/Users/UserCreate'),
);
const UserView = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "users" */ './pages/Users/UserView'));

// ROLES
const RolesList = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "roles" */ './pages/Roles/RolesList'));
const RoleEdit = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "roles" */ './pages/Roles/RoleEdit'));
const RoleCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "roles" */ './pages/Roles/RoleCreate'),
);

// SKILLS
const SkillsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "skills" */ './pages/Skills/SkillsList'),
);
const SkillEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "skills" */ './pages/Skills/SkillEdit'),
);
const SkillCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "skills" */ './pages/Skills/SkillCreate'),
);

// PROJECTS
const ProjectsList = lazy(
  () => import(/* webpackPreload: true, webpackChunkName: "projects" */ './pages/Projects/ProjectsList'),
);
const ProjectCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectCreate'),
);
const ProjectSettings = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectSettings'),
);
const ProjectDashboard = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectDashboard'),
);
const ProjectLogs = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectLogs'),
);
// PROJECTS - TASKS
const ProjectTasksList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "projects" */ './pages/Projects/ProjectTasksList'),
);
const ProjectTaskCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "connectors" */ './pages/Projects/ProjectTaskCreate'),
);
// PROJECTS - WORKINSTRUCTIONS
const ProjectWorkinstructionCreate = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "workinstructions" */ './pages/Projects/ProjectWorkinstructionCreate'
    ),
);
const ProjectWorkinstructionsList = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "workinstructions" */ './pages/Projects/ProjectWorkinstructionsList'
    ),
);
// PROJECTS - RULES
const ProjectRulesList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "rules" */ './pages/Projects/ProjectRulesList'),
);
const ProjectRuleCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "rules" */ './pages/Projects/ProjectRuleCreate'),
);
// PROJECTS - CONNECTORS
const ProjectConnectorsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "connectors" */ './pages/Projects/ProjectConnectorsList'),
);
const ProjectConnectorCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "connectors" */ './pages/Projects/ProjectConnectorCreate'),
);

// PROJECTS - FUNCTIONS
const ProjectFunctionsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "functions" */ './pages/Projects/ProjectFunctionsList'),
);
const ProjectFunctionsCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "functions" */ './pages/Projects/ProjectFunctionCreate'),
);
const ProjectFunctionsEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "functions" */ './pages/Projects/ProjectFunctionEdit'),
);

// DATABASES
const DatabasesList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabasesList'),
);
const DatabaseItemsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseItemsList'),
);
const DatabaseCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseCreate'),
);
const DatabaseSettings = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseSettings'),
);
const DatabaseItemCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseItemCreate'),
);
const DatabaseItemEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseItemEdit'),
);
const DatabaseViewItemsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "databases" */ './pages/Databases/DatabaseViewItemsList'),
);

// LOCATIONS
const LocationsList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "locations" */ './pages/Locations/LocationsList'),
);
const LocationCreate = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "locations" */ './pages/Locations/LocationCreate'),
);
const LocationEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "locations" */ './pages/Locations/LocationEdit'),
);

// CONNECTORS
const ConnectorEdit = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "connectors" */ './pages/Connectors/ConnectorEdit'),
);

// WORKINSTRUCTIONS
const WorkinstructionEdit = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "workinstructions" */ './pages/Workinstructions/WorkinstructionEdit'
    ),
);

// RULES
const RuleEdit = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "rules" */ './pages/Rules/RuleEdit'));

const Settings = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "settings" */ './pages/Settings'));

const EditShiftPlanForm = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "settings" */ './pages/Settings/ShiftPlans/ShiftPlanEdit'),
);

const CreateShiftPlanForm = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "settings" */ './pages/Settings/ShiftPlans/ShiftPlanCreate'),
);

// TODO: Remove FilterDemo after integration task for database views. Temporary page just to test the UI for the database views filter.
// const FilterDemo = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "login" */ './pages/Filter'));

const NotFound = lazy(() => import('./pages/NotFound'));

export enum Routes {
  Root = '/',
  Login = '/login',
  LandingSSO = '/landing-sso',
  ResetPasswordRequest = '/reset-password',
  ResetPassword = '/reset-password/:resetToken',
  Logout = '/logout',
  Users = '/users',
  UserCreate = '/users/create',
  UserView = '/users/:userId',
  UserEdit = '/users/:userId/edit',
  Roles = '/roles',
  RoleCreate = '/roles/create',
  RoleEdit = '/roles/:roleId/edit',
  Skills = '/skills',
  SkillCreate = '/skills/create',
  SkillEdit = '/skills/:skillId/edit',
  Projects = '/projects',
  ProjectCreate = '/projects/create',
  Project = '/projects/:projectId',
  ProjectSettings = '/projects/:projectId/settings',
  ProjectEdit = '/projects/:projectId/edit',
  ProjectDashboard = '/projects/:projectId/dashboard',
  ProjectTasks = '/projects/:projectId/tasks',
  ProjectTaskCreate = '/projects/:projectId/tasks/create',
  ProjectRules = '/projects/:projectId/rules',
  ProjectRuleCreate = '/projects/:projectId/rules/create',
  ProjectRuleEdit = '/projects/:projectId/rules/:ruleId/edit',
  ProjectWorkinstructions = '/projects/:projectId/workinstructions',
  ProjectWorkinstructionCreate = '/projects/:projectId/workinstructions/create',
  ProjectWorkinstructionEdit = '/projects/:projectId/workinstructions/:workinstructionId/edit',
  ProjectConnectors = '/projects/:projectId/connectors',
  ProjectConnectorCreate = '/projects/:projectId/connectors/create',
  ProjectConnectorEdit = '/projects/:projectId/connectors/:connectorId/edit',
  ProjectFunctions = '/projects/:projectId/functions',
  ProjectFunctionCreate = '/projects/:projectId/functions/create',
  ProjectFunctionEdit = '/projects/:projectId/functions/:functionId/edit',
  ProjectLogs = '/projects/:projectId/logs',
  Devices = '/devices',
  Locations = '/locations',
  LocationCreate = '/locations/create',
  LocationEdit = '/locations/:locationId/edit',
  Databases = '/databases',
  DatabaseCreate = '/databases/create',
  DatabaseSettings = '/databases/:databaseIdOrSlug/settings',
  DatabaseItems = '/databases/:databaseIdOrSlug',
  DatabaseItemCreate = '/databases/:databaseIdOrSlug/items/create',
  DatabaseItemEdit = '/databases/:databaseIdOrSlug/items/:itemId/edit',
  DatabaseViews = '/databases/:databaseIdOrSlug/views',
  DatabaseViewItems = '/databases/:databaseIdOrSlug/views/:viewId',
  Workbench = '/workbench',
  Settings = '/settings/:currentTab',
  SettingsNetworkSetup = '/settings/network-setup',
  SettingsShiftPlanCreate = '/settings/shift-plans/create',
  SettingsShiftPlanEdit = '/settings/shift-plans/:shiftPlanId/edit',
  // TODO: Remove FilterDemo after database view API integration task
  FilterDemo = '/filter-demo',
}

const routes = () => (
  <Suspense fallback={<PageLoader />}>
    <Switch>
      {/* Uncomment the line below to test the database view filter UI on the /filter-demo page */}
      {/* <LoggedInRoute path={Routes.FilterDemo} exact component={FilterDemo} /> */}
      <LoggedInRoute path={Routes.LandingSSO} exact component={LandingSSO} />
      <LoggedOutRoute path={Routes.Login} exact component={Login} />
      <LoggedOutRoute path={Routes.ResetPasswordRequest} exact component={ResetPassword} />
      <LoggedOutRoute path={Routes.ResetPassword} exact component={ResetPasswordNewPassword} />
      <LoggedInRoute path={Routes.Logout} exact component={Logout} />
      <LoggedInRoute path={Routes.Users} exact component={UsersList} />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Users}
        path={Routes.UserCreate}
        exact
        component={UserCreate}
      />
      <LoggedInRoute path={Routes.UserView} exact component={UserView} />
      <LoggedInRoute path={Routes.UserEdit} exact component={UserEdit} />
      <LoggedInRoute path={Routes.Roles} exact component={RolesList} />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Roles}
        path={Routes.RoleCreate}
        exact
        component={RoleCreate}
      />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Roles}
        path={Routes.RoleEdit}
        exact
        component={RoleEdit}
      />
      <LoggedInRoute path={Routes.Skills} exact component={SkillsList} />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Skills}
        path={Routes.SkillCreate}
        exact
        component={SkillCreate}
      />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Skills}
        path={Routes.SkillEdit}
        exact
        component={SkillEdit}
      />
      <LoggedInRoute path={Routes.Projects} exact component={ProjectsList} />
      <Redirect from={Routes.ProjectDashboard} to={Routes.Project} />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Projects}
        path={Routes.ProjectCreate}
        exact
        component={ProjectCreate}
      />
      <LoggedInRoute path={Routes.Project} exact component={ProjectDashboard} />
      <LoggedInRoute path={Routes.ProjectSettings} exact component={ProjectSettings} />
      <LoggedInRoute path={Routes.ProjectLogs} exact component={ProjectLogs} />
      <LoggedInRoute path={Routes.ProjectTasks} exact component={ProjectTasksList} />
      <LoggedInRoute path={Routes.ProjectTaskCreate} exact component={ProjectTaskCreate} />
      <LoggedInRoute path={Routes.ProjectRules} exact component={ProjectRulesList} />
      <LoggedInRoute path={Routes.ProjectRuleCreate} exact component={ProjectRuleCreate} />
      <LoggedInRoute path={Routes.ProjectRuleEdit} exact component={RuleEdit} />
      <LoggedInRoute path={Routes.ProjectWorkinstructions} exact component={ProjectWorkinstructionsList} />
      <LoggedInRoute path={Routes.ProjectWorkinstructionCreate} exact component={ProjectWorkinstructionCreate} />
      <LoggedInRoute path={Routes.ProjectWorkinstructionEdit} exact component={WorkinstructionEdit} />
      <LoggedInRoute path={Routes.ProjectConnectors} exact component={ProjectConnectorsList} />
      <LoggedInRoute path={Routes.ProjectConnectorCreate} exact component={ProjectConnectorCreate} />
      <LoggedInRoute path={Routes.ProjectConnectorEdit} exact component={ConnectorEdit} />
      <LoggedInRoute path={Routes.ProjectFunctions} exact component={ProjectFunctionsList} />
      <LoggedInRoute path={Routes.ProjectFunctionCreate} exact component={ProjectFunctionsCreate} />
      <LoggedInRoute path={Routes.ProjectFunctionEdit} exact component={ProjectFunctionsEdit} />
      <LoggedInRoute path={Routes.Devices} exact component={DevicesList} />
      <LoggedInRoute path={Routes.Locations} exact component={LocationsList} />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Locations}
        path={Routes.LocationCreate}
        exact
        component={LocationCreate}
      />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Locations}
        path={Routes.LocationEdit}
        exact
        component={LocationEdit}
      />
      <LoggedInRoute path={Routes.Databases} exact component={DatabasesList} />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.Databases}
        path={Routes.DatabaseCreate}
        exact
        component={DatabaseCreate}
      />
      <LoggedInRoute path={Routes.DatabaseItems} exact component={DatabaseItemsList} />
      <LoggedInRoute path={Routes.DatabaseItemCreate} exact component={DatabaseItemCreate} />
      <LoggedInRoute path={Routes.DatabaseItemEdit} exact component={DatabaseItemEdit} />
      <LoggedInRoute path={Routes.DatabaseSettings} exact component={DatabaseSettings} />
      <LoggedInRoute path={Routes.DatabaseViews} exact component={DatabaseItemsList} />
      <LoggedInRoute path={Routes.DatabaseViewItems} exact component={DatabaseViewItemsList} />
      <LoggedInRoute path={Routes.Workbench} exact component={Workbench} />
      <LoggedInRoute path={Routes.Settings} exact component={Settings} />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.SettingsNetworkSetup}
        path={Routes.SettingsShiftPlanEdit}
        exact
        component={EditShiftPlanForm}
      />
      <LoggedInRoute
        shouldBeRootAdministrator
        ifNotRootAdminRedirectTo={Routes.SettingsNetworkSetup}
        path={Routes.SettingsShiftPlanCreate}
        exact
        component={CreateShiftPlanForm}
      />
      <Redirect from={Routes.Root} exact to={Routes.Projects} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Suspense>
);

export default routes;
