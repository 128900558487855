import React, { ReactNode } from 'react';

import styles from './MainContainer.module.scss';

interface Props {
  children: ReactNode;
}

/**
 * Simple UI Component used for the main container on many pages.
 */
const MainContainer: React.FC<Props> = ({ children }) => <div className={styles.container}>{children}</div>;

export default MainContainer;
