// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderContainer-module__headerContainer___Z7zTC{z-index:10;box-shadow:0px 2px 6px rgba(182,182,182,.2)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `HeaderContainer-module__headerContainer___Z7zTC`
};
export default ___CSS_LOADER_EXPORT___;
