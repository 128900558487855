/* eslint-disable max-classes-per-file */
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import mongoose from 'mongoose';
import { CurrentTaskAction } from '../CurrentTaskAction';
import NotificationTypes from '../NotificationTypes';
import { NotifyLocationTypes, RuleDatabaseActions, RuleDatabaseItemFieldActions } from '../Rule';
import { ComparingConditionOperator, DirectConditionOperator } from '../RuleCondition/ConditionOperator';
import { TriggerSource, TriggerType } from '../RuleTrigger';
import { TaskAnnouncementType } from '../TaskAnnouncementType';
import { ReqPayloadFormat, ReqMethod as ReqPayloadOptions, ReqPayloadType } from '../global';
import { AssignmentType } from '../rules/AssignmentType';
import { AssignmentSkillTypes } from '../rules/assignment/Skills';
import { ModelNames } from './ModelNames';
import { MetaDefinition, MetaInterface } from './utils/DocumentMetaData';

export type RuleDocument = Rule & mongoose.Document;
export const VERSION = 25;

@Schema({ _id: false })
export class ReqHeaders {
  @Prop()
  name: string;

  @Prop()
  value: string;

  @Prop()
  type: string;
}

@Schema({ _id: false })
export class Trigger {
  @Prop({ enum: Object.values(TriggerType), type: String })
  type: TriggerType;

  @Prop({ enum: Object.values(TriggerSource), type: String })
  source: TriggerSource;

  @Prop()
  resourceId: string;

  @Prop()
  schedule: string;
}

@Schema({ _id: false })
export class RuleAnnouncement {
  @Prop({ type: String, enum: Object.values(TaskAnnouncementType), default: TaskAnnouncementType.NOTIFICATION })
  type: TaskAnnouncementType;

  @Prop({ type: String, enum: Object.values(CurrentTaskAction) })
  currentTaskAction: CurrentTaskAction;
}

@Schema({ _id: false })
export class BaseRuleTrigger {
  @Prop({ type: String, enum: Object.values(TriggerType) })
  type: TriggerType;

  @Prop()
  source: string;
}

@Schema({ _id: false })
export class SimpleRuleTrigger extends BaseRuleTrigger {
  @Prop({ type: String, enum: [TriggerType.INTERNAL_EVENT] })
  type: TriggerType.INTERNAL_EVENT;

  @Prop({
    type: String,
    enum: [
      TriggerSource.USER_CHANGE,
      TriggerSource.USER_LOGIN,
      TriggerSource.USER_LOGOUT,
      TriggerSource.LOCATION_CHANGE,
      TriggerSource.BARCODE_SCAN,
      TriggerSource.TASK_CHANGE,
      TriggerSource.INCOMING_EMAIL,
      TriggerSource.DATABASE_ITEM_CHANGE,
    ],
  })
  source:
    | TriggerSource.USER_CHANGE
    | TriggerSource.USER_LOGIN
    | TriggerSource.USER_LOGOUT
    | TriggerSource.LOCATION_CHANGE
    | TriggerSource.BARCODE_SCAN
    | TriggerSource.TASK_CHANGE
    | TriggerSource.INCOMING_EMAIL
    | TriggerSource.DATABASE_ITEM_CHANGE;
}

@Schema({ _id: false })
export class ResourceRuleTrigger extends BaseRuleTrigger {
  @Prop({
    type: String,
    enum: [TriggerSource.INCOMING_EVENT, TriggerSource.FUNCTION_CALL, TriggerSource.MQTT_CONNECTOR],
  })
  source: TriggerSource.INCOMING_EVENT | TriggerSource.FUNCTION_CALL | TriggerSource.MQTT_CONNECTOR;

  @Prop()
  resourceId: string;
}

@Schema({ _id: false })
export class ScheduleRuleTrigger extends BaseRuleTrigger {
  type: TriggerType.INTERNAL_EVENT;

  @Prop({ type: String, enum: [TriggerSource.SCHEDULE] })
  source: TriggerSource.SCHEDULE;

  @Prop()
  schedule: string;
}

export type RuleTrigger = SimpleRuleTrigger | ScheduleRuleTrigger | ResourceRuleTrigger;

@Schema({ _id: false })
export class DatabasePayload {
  @Prop({
    required: true,
    type: String,
    enum: Object.keys(RuleDatabaseItemFieldActions),
  })
  action: RuleDatabaseItemFieldActions;

  @Prop({
    trim: true,
  })
  value: string;

  @Prop()
  slug: string;

  @Prop({ type: mongoose.Schema.Types.ObjectId, required: true })
  _id: string;
}

@Schema({ _id: false })
export class RuleTask {
  @Prop({ trim: true })
  title: string;

  @Prop({ trim: true })
  headline: string;

  @Prop({ trim: true })
  description: string;

  @Prop({ default: 3 })
  priority: string;

  @Prop({ type: SchemaFactory.createForClass(RuleAnnouncement) })
  announcement: RuleAnnouncement;
}

@Schema({ _id: false })
export class Email {
  @Prop()
  from: string;

  @Prop()
  to: string;

  @Prop()
  subject: string;

  @Prop()
  text: string;

  @Prop()
  attachVariables: boolean; // works only for TaskChange and DatabaseItemCahnge triggers
}

@Schema({ _id: false })
export class ReqPayload {
  @Prop()
  mapFrom: string;

  @Prop()
  mapTo: string;

  @Prop({
    type: String,
    enum: Object.values(ReqPayloadType),
    default: ReqPayloadType.VARIABLE,
  })
  type: ReqPayloadType;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Schema({ discriminatorKey: 'type', _id: false, overwriteModels: true })
export class ActionBase {
  @Prop({ type: String, required: true, enum: Object.values(AssignmentType), $skipDiscriminatorCheck: true })
  type: AssignmentType;
}

@Schema({ _id: false })
export class DeviceLocationChangeAction extends ActionBase {
  type: AssignmentType.DEVICE_LOCATION_CHANGE;

  @Prop()
  deviceIds: string[]; // Can contain ids or a var names i.e. $deviceId

  @Prop()
  locationId: string; // Can contain an id or a var name i.e. $locationId
}

@Schema({ _id: false })
export class RolesAction extends ActionBase {
  type: AssignmentType.ROLES;

  @Prop()
  userId: string; // Can contain an id or a var name i.e. $caller.id

  @Prop([{ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Role }])
  rolesToAdd: string[];

  @Prop([{ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Role }])
  rolesToRemove: string[];
}

@Schema({ _id: false })
export class SkillsAction extends ActionBase {
  type: AssignmentType.SKILLS;

  @Prop()
  userId: string; // Can contain an id or a var name i.e. $caller.id

  @Prop([{ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Skill }])
  skillsToAdd: string[];

  @Prop([{ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Skill }])
  skillsToRemove: string[];
}

@Schema({ _id: false })
export class FunctionAction extends ActionBase {
  type: AssignmentType.FUNCTION;

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Function })
  functionId: string;

  @Prop()
  useCustomPayload: boolean;

  @Prop([{ type: SchemaFactory.createForClass(ReqPayload) }])
  reqPayload: ReqPayload[];

  @Prop()
  forwardReturnValue: boolean;

  @Prop()
  forwardUrl: string;
}

@Schema({ _id: false })
export class MqttPublishAction extends ActionBase {
  type: AssignmentType.MQTT_PUBLISH;

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Connector })
  connectorId: string;

  @Prop()
  topic: string;

  @Prop({ default: false })
  useCustomPayload: boolean;

  @Prop([{ type: SchemaFactory.createForClass(ReqPayload) }])
  customPayload: ReqPayload[];
}

@Schema({ _id: false })
export class MqttSubscribeAction extends ActionBase {
  type: AssignmentType.MQTT_SUBSCRIBE;

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Connector })
  connectorId: string;

  @Prop()
  topic: string;
}

@Schema({ _id: false })
export class TaskVariable {
  @Prop()
  name: string;

  @Prop()
  value: string;
}

@Schema({ _id: false })
export class UpdateTaskVariablesAction extends ActionBase {
  type: AssignmentType.UPDATE_TASK_VARIABLES;

  @Prop({ required: true })
  taskExternalId: string;

  @Prop([{ type: SchemaFactory.createForClass(TaskVariable) }])
  variables: TaskVariable[];
}

@Schema({ _id: false })
export class Condition {
  @Prop()
  name: string;

  @Prop({
    type: String,
    enum: [...Object.values(ComparingConditionOperator), ...Object.values(DirectConditionOperator)],
  })
  operator: DirectConditionOperator | ComparingConditionOperator;

  @Prop()
  value: string;
}

@Schema({ _id: false })
export class RuleAssignmentSkills {
  @Prop({ type: String, enum: Object.values(AssignmentSkillTypes) })
  type: AssignmentSkillTypes;

  @Prop()
  variables: string;

  @Prop([{ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Skill }])
  skills: string[];
}

@Schema({ _id: false })
export class RuleAssignment {
  @Prop({ type: SchemaFactory.createForClass(RuleAssignmentSkills) })
  skills: RuleAssignmentSkills;
}

@Schema({ _id: false })
export class NotifyLocation {
  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.LocationParts })
  partId: string;

  @Prop({ type: mongoose.Schema.Types.ObjectId })
  level: string;

  @Prop({ default: null })
  variable: string;

  @Prop({
    type: String,
    enum: Object.values(NotifyLocationTypes),
    default: NotifyLocationTypes.ALL,
  })
  type: NotifyLocationTypes;
}

export type RuleAction =
  | DeviceLocationChangeAction
  | FunctionAction
  | SkillsAction
  | RolesAction
  | MqttPublishAction
  | UpdateTaskVariablesAction;

@Schema({ _id: false })
class Timeout {
  @Prop()
  seconds: number;
}

type ActionType = Exclude<
  AssignmentType,
  | AssignmentType.DATABASE
  | AssignmentType.DELEGATE
  | AssignmentType.EMAIL
  | AssignmentType.EVENT
  | AssignmentType.MESSAGE
  | AssignmentType.TASK
  | AssignmentType.TASK_STATUS_CHANGE
  | AssignmentType.WEBHOOK
  | AssignmentType.WORKBENCH
  | AssignmentType.WORKINSTRUCTION
>;

// TODO: use AssignmentType instead of partial ActionType after migrating others discriminators
// See: https://workerbase.atlassian.net/browse/WB-2559
const RuleActionDiscriminators: { [type in ActionType]: mongoose.Schema } = {
  [AssignmentType.DEVICE_LOCATION_CHANGE]: SchemaFactory.createForClass(DeviceLocationChangeAction),
  [AssignmentType.ROLES]: SchemaFactory.createForClass(RolesAction),
  [AssignmentType.SKILLS]: SchemaFactory.createForClass(SkillsAction),
  [AssignmentType.FUNCTION]: SchemaFactory.createForClass(FunctionAction),
  [AssignmentType.MQTT_PUBLISH]: SchemaFactory.createForClass(MqttPublishAction),
  [AssignmentType.MQTT_SUBSCRIBE]: SchemaFactory.createForClass(MqttSubscribeAction),
  [AssignmentType.UPDATE_TASK_VARIABLES]: SchemaFactory.createForClass(UpdateTaskVariablesAction),
};

@Schema({ _id: false })
export class InitialStepObjectId {
  @Prop({ default: false })
  isVar: boolean;

  @Prop()
  variable?: string;

  @Prop({ type: String })
  value?: string; // step._id || step.id
}

@Schema()
export class Rule {
  @Prop()
  name: string;

  @Prop()
  description: string;

  @Prop({ type: String, enum: Object.values(AssignmentType) })
  assignmentType: AssignmentType;

  @Prop()
  url: string;

  @Prop([[{ type: SchemaFactory.createForClass(Condition) }]])
  filter: Condition[][];

  @Prop()
  route: string;

  @Prop([{ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Rule }])
  parents: string[];

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Database })
  database: string;

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Connector })
  event: string;

  @Prop({ default: false })
  notificationForce: boolean;

  @Prop({ default: false })
  suspendTaskWhenNoUserAvailable: boolean;

  @Prop({ default: false })
  suspendTaskWhenNoUserIsAssignable: boolean;

  @Prop({
    default: NotificationTypes.ALL_USERS,
    type: String,
    enum: Object.keys(NotificationTypes),
  })
  notificationType: NotificationTypes;

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Connector })
  assignmentEvent: string;

  @Prop()
  type: string;

  @Prop({ default: false })
  notifyLocationIsVar: boolean;

  @Prop()
  notifyLocationVar: string;

  @Prop([{ type: SchemaFactory.createForClass(NotifyLocation) }])
  notifyLocations: NotifyLocation[];

  @Prop({
    type: mongoose.Schema.Types.ObjectId,
    ref: ModelNames.Location,
  })
  location: string;

  @Prop({ default: false })
  autostart: boolean;

  @Prop([{ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Role }])
  roles: string[];

  @Prop({ type: SchemaFactory.createForClass(RuleAssignment) })
  assignment: RuleAssignment;

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Project })
  project: string;

  @Prop({
    type: mongoose.Schema.Types.ObjectId,
    ref: ModelNames.Workinstructions,
  })
  workinstructions: string;

  @Prop({ type: SchemaFactory.createForClass(InitialStepObjectId) })
  initialStepObjectId: InitialStepObjectId;

  @Prop({ default: false })
  noAction: boolean;

  @Prop({ default: true })
  active: boolean;

  @Prop({ type: String, default: null })
  announcementDelay: string | null; // Time is seconds or variable name that starts with $

  @Prop({ type: String, default: null })
  ttl: number | string | null;

  @Prop({ type: String, default: null })
  dueInSeconds: string | null;

  @Prop({ type: String, default: null })
  reannouncePeriod: number | string | null; // Time is seconds or variable name that starts with $

  @Prop({ default: false })
  autoRejectWhenBusy: boolean;

  @Prop({ default: false })
  changeTasksStatusTo: string;

  @Prop({ default: null })
  taskExternalId: string;

  @Prop({ default: false })
  selfinstruction: boolean;

  @Prop([{ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Reason }])
  rejectReasons: string[];

  @Prop({ default: false })
  isUserTask: boolean;

  @Prop({ default: false })
  isAssignedUserVar: boolean;

  @Prop({ default: false })
  isUserMessage: boolean;

  @Prop()
  message: string;

  @Prop({ type: SchemaFactory.createForClass(Timeout) })
  timeout?: Timeout | null;

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.User })
  assignedUser: string;

  @Prop({
    type: String,
    enum: Object.values(ReqPayloadOptions),
    default: ReqPayloadOptions.POST,
  })
  reqMethod: ReqPayloadOptions;

  @Prop({
    type: String,
    enum: Object.values(ReqPayloadFormat),
    default: ReqPayloadFormat.JSON,
  })
  reqPayloadFormat: ReqPayloadFormat;

  @Prop({ default: 'root' })
  reqPayloadRootName: string;

  @Prop([{ type: SchemaFactory.createForClass(ReqHeaders) }])
  reqHeaders: ReqHeaders[];

  @Prop([{ type: SchemaFactory.createForClass(ReqPayload) }])
  reqPayload: ReqPayload[];

  @Prop({ type: SchemaFactory.createForClass(Email) })
  email: Email;

  @Prop({ type: SchemaFactory.createForClass(RuleTask) })
  task: RuleTask;

  @Prop({ type: String, enum: [...Object.values(RuleDatabaseActions), null], default: null })
  databaseAction: string;

  @Prop()
  databaseUniqueID: string;

  @Prop([{ type: SchemaFactory.createForClass(DatabasePayload) }])
  databasePayload: DatabasePayload[];

  @Prop({ default: false })
  useCustomPayload: boolean;

  @Prop()
  assignedUserVar: string[];

  @Prop({ default: false })
  forwardWebhookResponse: boolean;

  @Prop()
  forwardUrl: string;

  @Prop({ type: Object }) // TODO: NEST add type
  additionalVariables: any;

  @Prop({ type: SchemaFactory.createForClass(Trigger) })
  trigger: RuleTrigger;

  @Prop({
    type: SchemaFactory.createForClass(ActionBase),
    discriminators: RuleActionDiscriminators,
  })
  action: RuleAction;

  @Prop({ default: null })
  triggeredAt: Date;

  @Prop(MetaDefinition(VERSION))
  meta: MetaInterface;
}
