import { SortOrder } from '@workerbase/types/global';
import { Sorting } from '@workerbase/types/ListConfig';
import { Translation } from '@workerbase/types/Translation';
import { TS } from '@workerbase/utils/TS';
import { NodeRedFlowGET, SettingsGET } from 'services/types/Settings';
import { PaginatedQueryParams, PaginatedResponse } from '@workerbase/types/api/pagination';
import { Languages } from '@workerbase/types/Settings';
import { ReSyncBody } from '@workerbase/types/api/settings';
import { api } from './api';

const SETTINGS_ENDPOINT = '/api/v1/settings';

export const getSettings = async (): Promise<SettingsGET> => {
  const { data } = await api.get<SettingsGET>(SETTINGS_ENDPOINT);

  return data;
};

export const getLanguages = async (): Promise<string[]> => {
  const {
    data: { data },
  } = await api.get<{ data: string[] }>(`${SETTINGS_ENDPOINT}/languages`);

  return data;
};

export const updateLanguages = async (languages: string): Promise<Languages[]> => {
  const {
    data: { data },
  } = await api.put<{ data: Languages[] }>(`${SETTINGS_ENDPOINT}/languages`, languages);
  return data;
};

export const triggerElasticSync = async (body: ReSyncBody): Promise<ReSyncBody> => {
  const { data } = await api.post<ReSyncBody>(`${SETTINGS_ENDPOINT}/re-sync`, body);
  return data;
};

export const getNodeRedFlows = async (): Promise<NodeRedFlowGET[]> => {
  const {
    data: { data },
  } = await api.get<{ data: NodeRedFlowGET[] }>(`${SETTINGS_ENDPOINT}/nodeRedFlows`);

  return data;
};

type GetTranslationsPaginated = (
  page: number,
  perPage: number,
  sorting?: Sorting,
  filtering?: string,
) => Promise<PaginatedResponse<Translation[]>>;

export const getTranslationsPaginated: GetTranslationsPaginated = async (
  page = 1,
  perPage = 20,
  sorting,
  filtering,
) => {
  const params: PaginatedQueryParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector ?? TS.property<Translation>()('value').key,
    order: sorting?.sortDirection ?? SortOrder.ASC,
    textSearch: filtering,
  };

  const { data } = await api.get<PaginatedResponse<Translation[]>>(`${SETTINGS_ENDPOINT}/translations`, {
    params,
  });

  return data;
};

// TODO: set return type
export const updateTranslations = async (translations: Translation[]): Promise<unknown> => {
  const { data } = await api.put<unknown>(`${SETTINGS_ENDPOINT}/translations/batch`, translations);

  return data;
};
