// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LandingSSO-module__container___yn_pn{display:flex;margin-top:12px;padding:12px 0;flex-direction:column;width:100%;row-gap:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LandingSSO-module__container___yn_pn`
};
export default ___CSS_LOADER_EXPORT___;
