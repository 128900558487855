import { updatePaginationFactory } from '@redux/common/ListConfig/actions';
import { TranslationPUT } from '@workerbase/types/api/translations';
import { Translation } from '@workerbase/types/Translation';
import { PaginationMeta } from '@workerbase/types/Response';
import { BaseAction } from '../common/actions';

export enum TranslationsActions {
  GET_TRANSLATIONS_PAGINATED = '@@translations/GET_TRANSLATIONS_PAGINATED_REQUEST',
  GET_TRANSLATIONS_PAGINATED_SUCCESS = '@@translations/GET_TRANSLATIONS_PAGINATED_SUCCESS',
  UPDATE_TRANSLATIONS = '@@translations/UPDATE_TRANSLATIONS',
  UPDATE_PAGINATION = '@@translations/UPDATE_PAGINATION',
}

export interface GetTranslationsPaginatedSuccessPayload {
  translations: Translation[];
  meta: PaginationMeta;
}

export const getTranslationsPaginatedRequestAction = (): BaseAction => ({
  type: TranslationsActions.GET_TRANSLATIONS_PAGINATED,
  payload: {},
});

export const getTranslationsPaginatedSuccessAction = (
  translations: Translation[],
  meta: PaginationMeta,
): BaseAction => ({
  type: TranslationsActions.GET_TRANSLATIONS_PAGINATED_SUCCESS,
  payload: {
    translations,
    meta,
  } as GetTranslationsPaginatedSuccessPayload,
});

export const updateTranslationsAction = (translations: TranslationPUT[]): BaseAction => ({
  type: TranslationsActions.UPDATE_TRANSLATIONS,
  payload: {
    translations,
  },
});

export const updatePaginationAction = updatePaginationFactory(TranslationsActions.UPDATE_PAGINATION);
