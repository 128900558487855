import React from 'react';
import { Redirect } from 'react-router';

import { useSelector } from 'react-redux';
import { getIsAuthenticated, getUser } from '@redux/Login';
import { RootState } from '@redux/types';

import { LoggedUser } from 'services/types/User';
import { toast } from 'react-toastify';
import { Routes } from '../../routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- to allow any props
interface LoggedInRouteProps extends Record<string, any> {
  shouldBeRootAdministrator?: boolean;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- to allow any props
  component: React.ComponentType<any>;
  ifNotRootAdminRedirectTo?: Routes;
}

const LoggedInRoute: React.FC<LoggedInRouteProps> = ({
  component: Component,
  path,
  computedMatch,
  shouldBeRootAdministrator = false,
  ifNotRootAdminRedirectTo = Routes.Login,
  ...otherProps
}) => {
  const isAuthenticated = useSelector<RootState, boolean>(getIsAuthenticated);
  const loggedInUser = useSelector<RootState, LoggedUser | null>(getUser);

  if (!isAuthenticated || loggedInUser === null) {
    return <Redirect to={Routes.Login} />;
  }

  if (shouldBeRootAdministrator && !loggedInUser.isRootAdministrator) {
    toast.error(`Only root administrator can access this page`, {
      toastId: 'permission-denied', // temp fix to prevent toast showing up twice
    });

    return <Redirect to={ifNotRootAdminRedirectTo} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading -- For these special Router route, we allow props spreading.
  return <Component match={computedMatch} {...otherProps} />;
};

export default LoggedInRoute;
