// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainFlexContainer-module__container___XlCxp{position:relative;height:100%}.MainFlexContainer-module__container___XlCxp .MainFlexContainer-module__contentWrapper___eJFG7{position:absolute;height:100%;width:100%;overflow-y:scroll;overflow-x:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainFlexContainer-module__container___XlCxp`,
	"contentWrapper": `MainFlexContainer-module__contentWrapper___eJFG7`
};
export default ___CSS_LOADER_EXPORT___;
