import { Languages } from '@workerbase/types/Settings';
import { LoggedUser } from 'services/types/User';
import * as Sentry from '@sentry/react';
import { RootState } from '../types';
import { BACKEND_HOSTNAME } from '../../globals';

export const getUserToken = (store: RootState): string | null => store.login.token;

export const getUser = (store: RootState): LoggedUser | null => {
  if (store.login.user?.id) {
    Sentry.setUser({
      id: store.login.user?.id,
      name: `${store.login.user?.firstName} ${store.login.user?.lastName}`,
      email: store.login.user?.email,
      domain: BACKEND_HOSTNAME,
      DOMAIN: BACKEND_HOSTNAME,
    });
  }
  return store.login.user;
};

export const getLoginError = (store: RootState) => store.login.loginError;

export const getLoginSSOError = (store: RootState) => store.login.loginSSOError;

export const getIsAuthenticated = (store: RootState): boolean => store.login.isAuthenticated;

export const getUserTokenTTLDate = (store: RootState): string | null => store.login.tokenTTLDate;

export const getUserLanguage = (store: RootState): Languages => store.login.user?.language || Languages.EN;

export const getIsOnPremise = (store: RootState) => store.login.user?.isOnPremise || false;

export const getIsRootAdministrator = (store: RootState) => store.login.user?.isRootAdministrator || false;

export const getIsDeveloper = (store: RootState) => store.login.user?.isDeveloper || false;

export const getUserRoleIds = (store: RootState): string[] => store.login.user?.roleIds || [];

export const getIsWorkbenchEnabled = (store: RootState): boolean => store.login.user?.isWorkbenchEnabled || false;

export const getMyWorkEnabled = (store: RootState): boolean => store.login?.user?.isMyWorkEnabled || false;

export const getIsLoadingInitialListConfig = (store: RootState): boolean => store.login.isLoadingInitialListConfig;
