import { put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { HttpStatus } from '@workerbase/types/HttpStatus';

import { ToastUtils } from '../../utils/ToastUtils';
import { logoutUser } from '../Login';
import { CommonActions } from './actions';

function* handleRequestErrorSaga(action) {
  if (action.payload.error) {
    if (
      action.payload.error.response?.status === HttpStatus.FORBIDDEN ||
      action.payload.error.response?.status === HttpStatus.UNAUTHORIZED
    ) {
      // wait a bit so that takelatest can cancel the duplicate errors
      yield call(
        () =>
          new Promise<void>((resolve) => {
            setTimeout(() => resolve(), 100);
          }),
      );
      yield put(logoutUser());
      yield call(toast.error, 'Session expired. Please login.');
    } else {
      const errorReportingEventId = action.payload.error.response?.body?.errorReportingEventId;
      const errorMessages =
        action.payload?.error?.response?.body?.error?.additionalData?.errors || action.payload.error.message;
      if (errorReportingEventId) {
        const errorMessage = Array.isArray(errorMessages) ? errorMessages[0] : errorMessages;
        ToastUtils.showToastWithErrorReportingId(errorMessage, errorReportingEventId);
      } else {
        const errors = Array.isArray(errorMessages) ? errorMessages : [errorMessages];
        errors.forEach((error) => {
          if (action.payload.customMessage) {
            toast.error(action.payload.customMessage);
          } else {
            let errorMessage = typeof error === 'string' ? error : JSON.stringify(error);
            if (error.errorCode?.match(/openapi.requestValidation$/)) {
              errorMessage = `The request ${error.location} item ${error.path} ${error.message}`;
            }
            toast.error(errorMessage);
          }
        });
      }
    }
  }
}

export default function* commonSagas() {
  yield takeLatest(CommonActions.HANDLE_REQUEST_ERROR, handleRequestErrorSaga);
}
