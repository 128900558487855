// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BaseModal-module__modal___iMZOm{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);box-sizing:border-box;background-color:#fff;min-height:104px;width:660px;padding:32px;outline:0;box-shadow:0px 4px 16px rgba(0,0,0,.08);border-radius:6px;font-family:Proxima Nova;font-style:normal;font-weight:normal;font-size:16px;line-height:20px;letter-spacing:0px}.BaseModal-module__modalFullSize___s94R4{width:95%;height:95%}.BaseModal-module__modalBodyFullHeight___TAkWs{height:100%}.BaseModal-module__closeIcon___HeQge{position:absolute;right:0;top:0;padding:inherit}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `BaseModal-module__modal___iMZOm`,
	"modalFullSize": `BaseModal-module__modalFullSize___s94R4`,
	"modalBodyFullHeight": `BaseModal-module__modalBodyFullHeight___TAkWs`,
	"closeIcon": `BaseModal-module__closeIcon___HeQge`
};
export default ___CSS_LOADER_EXPORT___;
