/* eslint-disable import/no-import-module-exports */
import { loader } from '@monaco-editor/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import configureStore, { history } from './redux/configureStore';
import { ToastUtils } from './utils/ToastUtils';
import { SentryUtils } from './utils/SentryUtils';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `frontend@${VERSION}`,
    environment: window.location.hostname,
    attachStacktrace: true,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event: Sentry.Event) {
      if (!SentryUtils.shouldIgnoreEvent(event)) {
        ToastUtils.showToastWithErrorReportingId(SentryUtils.getMessageFromEvent(event), event.event_id);
      }
      return event;
    },
  });
}

if (module?.hot?.accept) {
  module.hot.accept();
}

loader.config({ paths: { vs: '/monaco-editor/vs' } });
const store = configureStore();

ReactDOM.render(<App store={store} history={history} />, document.getElementById('root'));
