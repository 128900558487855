// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionModal-module__contentContainer____KNfG{margin-bottom:24px}.ActionModal-module__buttonsContainer___brrPL{display:flex;justify-content:flex-end}.ActionModal-module__buttonsContainer___brrPL>*{margin-left:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": `ActionModal-module__contentContainer____KNfG`,
	"buttonsContainer": `ActionModal-module__buttonsContainer___brrPL`
};
export default ___CSS_LOADER_EXPORT___;
