// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/ProximaNova/ProximaNova-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/ProximaNova/ProximaNova-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/ProximaNova/ProximaNova-Semibold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:Proxima Nova;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype")}@font-face{font-family:Proxima Nova;font-weight:bold;src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype")}@font-face{font-family:Proxima Nova;font-weight:bold;src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype")}body{font-family:Proxima Nova;font-style:normal;font-weight:normal;font-size:16px;line-height:20px;letter-spacing:0px}h2{font-family:Proxima Nova;font-style:normal;font-weight:600;font-size:20px;line-height:24px;letter-spacing:0px;margin-bottom:24px;color:#252a37}pre,code,tt{font-family:monospace,sans-serif}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
