import {
  getUserTokenTTLDate,
  loginSSORequestFailure,
  loginSSORequestSuccess,
  logoutUser,
  logoutUserDelayed,
} from '@redux/Login';
import { RootState } from '@redux/types';
import { SSO_REDIRECT_LANDING_PAGE } from '@workerbase/types/auth/constants';
import moment from 'moment';
import React, { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapSSOQueryParamsToUser } from './util';

export const AuthProviderContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const userTokenTTLDate = useSelector<RootState, string | null>(getUserTokenTTLDate);
  const queryParams = new URLSearchParams(window.location.search);
  const isSuccessful = queryParams.get('success') === 'true';
  if (window.location.pathname === SSO_REDIRECT_LANDING_PAGE) {
    if (isSuccessful) {
      const loginData = mapSSOQueryParamsToUser(queryParams);
      dispatch(loginSSORequestSuccess(loginData));
    } else {
      const message = queryParams.get('message') ?? 'Unknown error';
      dispatch(loginSSORequestFailure(message));
    }
  } else if (userTokenTTLDate) {
    const logoutDelayInMS = moment(userTokenTTLDate).diff(moment(), 'milliseconds');
    if (logoutDelayInMS > 0) {
      dispatch(logoutUserDelayed(logoutDelayInMS));
    } else {
      dispatch(logoutUser());
    }
  } else {
    dispatch(logoutUser());
  }
  return <AuthProviderContext.Provider value={null}>{children}</AuthProviderContext.Provider>;
};
