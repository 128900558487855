import React, { ReactNode } from 'react';

import styles from './MainFlexContainer.module.scss';

interface Props {
  children: ReactNode;
}

/**
 * Simple UI Component used for the main flex container on many pages.
 */
const MainFlexContainer: React.FC<Props> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.contentWrapper}>{children}</div>
  </div>
);

export default MainFlexContainer;
