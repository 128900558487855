import { handleRequestError } from '@redux/common';
import { Translation } from '@workerbase/types/Translation';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getTranslationsPaginated, updateTranslations } from 'services/networking/settings';
import { PaginationMeta } from '@workerbase/types/Response';
import { getTranslationsPaginatedSuccessAction, TranslationsActions } from './actions';
import { getListConfigs } from './selectors';

export function* getTranslationsPaginatedSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);
    const response = yield call(
      getTranslationsPaginated,
      listConfigs.pagination.currentPage,
      listConfigs.pagination.itemsPerPage,
      listConfigs.sorting,
    );
    const translations: Translation[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getTranslationsPaginatedSuccessAction(translations, meta));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateTranslationsSaga(action) {
  try {
    yield call(updateTranslations, action.payload.translations);
    yield call(toast.success, 'Translations updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* translationsSagas() {
  yield takeLatest(
    [TranslationsActions.GET_TRANSLATIONS_PAGINATED, TranslationsActions.UPDATE_PAGINATION],
    getTranslationsPaginatedSaga,
  );
  yield takeLatest(TranslationsActions.UPDATE_TRANSLATIONS, updateTranslationsSaga);
}
