import { call, put, takeLatest, select, takeEvery, take } from 'redux-saga/effects';

import { getUserToken, getUser, getIsAuthenticated } from '@redux/Login/selectors';
import { logoutUser } from '@redux/Login/actions';

import { MqttActions, mqttLogin } from './actions';

import {
  endConnection,
  mqttPublish,
  mqttSubscribe,
  mqttUnsubscribe,
  mqttUpdateCallback,
  createMqttChannel,
  updateLoginToken,
} from './mqttClient';
import { isMqttAuthenticated } from './selectors';

export function* mqttLoginSaga() {
  const loginToken = yield select(getUserToken);
  const user = yield select(getUser);

  const mqttChannel = yield call(createMqttChannel, {
    userId: user?.id,
    loginToken,
  });

  while (true) {
    const mqqtAction = yield take(mqttChannel);
    yield put(mqqtAction);
  }
}

export function* handleMqttErrorSaga(action) {
  const { errorLoginToken, error } = action.payload;
  const loginToken = yield select(getUserToken);

  if (errorLoginToken !== loginToken) {
    return yield call(updateLoginToken, loginToken);
  }
  if (
    (error.message === 'Connection refused: Not authorized' && !loginToken) ||
    error.message === 'Connection refused: Bad username or password'
  ) {
    return yield put(logoutUser());
  }
  return yield;
}

export function* handleMqttLogoutSaga() {
  yield call(endConnection);
}

const callWithMqttAuthTest = (callee: (action) => void) =>
  function* (action) {
    const isAuthenticated = yield select(isMqttAuthenticated);
    if (isAuthenticated) {
      return yield call(callee, action.payload);
    }
    const isUserAuthenticated = yield select(getIsAuthenticated);
    if (isUserAuthenticated) {
      yield put(mqttLogin());
      yield take(MqttActions.MQTT_CONNECTED);
      return yield call(callee, action.payload);
    }
    return yield;
  };

export function* handleUpdateMqttCallback(action) {
  yield call(mqttUpdateCallback, action.payload);
}

export default function* MqttSagas() {
  yield takeLatest(MqttActions.MQTT_LOGOUT, handleMqttLogoutSaga);
  yield takeLatest(MqttActions.MQTT_ERROR, handleMqttErrorSaga);
  yield takeLatest(MqttActions.MQTT_LOGIN, mqttLoginSaga);
  yield takeEvery(MqttActions.MQTT_SUBSCRIBE, callWithMqttAuthTest(mqttSubscribe));
  yield takeEvery(MqttActions.MQTT_UPDATE_CALLBACK, handleUpdateMqttCallback);

  yield takeEvery(MqttActions.MQTT_UNSUBSCRIBE, callWithMqttAuthTest(mqttUnsubscribe));
  yield takeEvery(MqttActions.MQTT_PUBLISH, callWithMqttAuthTest(mqttPublish));
}
