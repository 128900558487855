/* eslint-disable max-classes-per-file */
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import mongoose, { LeanDocument } from 'mongoose';
import { ModelNames } from './ModelNames';
import { MetaDefinition, MetaInterface } from './utils/DocumentMetaData';

export type DatabaseDocument = Database & mongoose.Document;
export const VERSION = 3;

export const COLUMN_ARRAY_TYPE_SUFFIX = 'Array';

export enum DatabaseColumnTypes {
  STRING = 'String',
  STRING_ARRAY = 'StringArray',
  INTEGER = 'Integer',
  DOUBLE = 'Double',
  LOCATION = 'Location',
  LOCATION_ARRAY = 'LocationArray',
  BOOLEAN = 'Boolean',
  DATETIME = 'DateTime',
  DATE = 'Date',
  TIME = 'Time',
  USER = 'User',
  USER_ARRAY = 'UserArray',
  ROLE = 'Role',
  // ROLE_ARRAY = 'RoleArray',
  SKILL = 'Skill',
  // SKILL_ARRAY = 'SkillArray',
  MEDIA = 'Media',
  MEDIA_ARRAY = 'MediaArray',
  REFERENCE = 'Reference',
  // REFERENCE_ARRAY = 'ReferenceArray',
}

export enum DatabaseExternalIdColumnTypes {
  DOUBLE = 'Double',
  STRING = 'String',
  INTEGER = 'Integer',
}

@Schema({ _id: false })
export class DatabaseHistoryConfig {
  @Prop({ default: false })
  enabled: boolean;

  @Prop({ default: 0 })
  expiresInSec: number;
}

// without _id set to true mongoose doesn't save _id in an array of sub-documents
@Schema({ _id: true })
export class DatabaseColumnDocument extends mongoose.Document {
  @Prop({
    type: String,
    enum: Object.values(DatabaseColumnTypes),
    default: DatabaseColumnTypes.STRING,
  })
  type: DatabaseColumnTypes;

  @Prop()
  reference?: string; // database slug, only for DatabaseColumnTypes.REFERENCE

  @Prop({ required: true })
  name: string;

  @Prop({ required: true })
  slug: string;

  @Prop({ default: false })
  isOptional: boolean;

  @Prop({ default: false })
  isDeleted: boolean;
}

export type DatabaseColumn = LeanDocument<DatabaseColumnDocument> & { _id: string };

@Schema()
export class Database {
  _id: string;

  @Prop({ type: SchemaFactory.createForClass(DatabaseHistoryConfig) })
  history: DatabaseHistoryConfig;

  @Prop({
    type: String,
    enum: Object.values(DatabaseExternalIdColumnTypes),
    default: DatabaseExternalIdColumnTypes.STRING,
  })
  externalIdType: DatabaseExternalIdColumnTypes;

  @Prop({ required: true })
  name: string;

  @Prop({ required: true, index: { unique: true, partialFilterExpression: { deleted: false } } })
  slug: string;

  @Prop({
    type: [{ ref: ModelNames.Role, type: mongoose.Schema.Types.ObjectId }],
  })
  readOnlyRoles: string[];

  @Prop({
    type: [{ ref: ModelNames.Role, type: mongoose.Schema.Types.ObjectId }],
  })
  readWriteRoles: string[];

  @Prop([{ type: SchemaFactory.createForClass(DatabaseColumnDocument) }])
  columns: DatabaseColumn[];

  @Prop(MetaDefinition(VERSION))
  meta: MetaInterface;
}
