// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsSideBar-module__version___adV1C{padding:14px 16px}.SettingsSideBar-module__version___adV1C a{color:#cdd2e0}.SettingsSideBar-module__version___adV1C a:hover{text-decoration:underline}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"version": `SettingsSideBar-module__version___adV1C`
};
export default ___CSS_LOADER_EXPORT___;
