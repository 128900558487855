import { HTTPMethod } from './global';

export enum RequestMethod {
  GET = HTTPMethod.GET,
  POST = HTTPMethod.POST,
  PUT = HTTPMethod.PUT,
  DELETE = HTTPMethod.DELETE,
}

// Payload related types are also used for Connector payload
export enum RequestPayloadFormat {
  FORM = 'FORM',
  JSON = 'JSON',
  XML = 'XML',
}
