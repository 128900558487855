import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/types';
import { getIsWorkbenchEnabled } from '@redux/Login/selectors';

import { Routes } from '../../routes';
import wbLogo from '../../assets/media/wb-logo-only.png';

import UserActions from './components/UserActions';
import styles from './HeaderNavigation.module.scss';
import { GlobalSettingsTabs } from '../../pages/Settings/SettingsSideBar/SettingsSideBar';

const HeaderNavigation: React.FC = () => {
  const isWorkbenchEnabled = useSelector<RootState, boolean>(getIsWorkbenchEnabled);

  return (
    <div className={styles.header}>
      <Link to="/" className={styles.logoLink}>
        <img src={wbLogo} />
      </Link>

      <nav>
        {/* <NavLink exact activeClassName={styles.active} to="/">
        <FormattedMessage id="header.applications" />
      </NavLink> */}
        <NavLink activeClassName={styles.active} to={Routes.Users}>
          <FormattedMessage id="header.users" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Roles}>
          <FormattedMessage id="header.roles" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Skills}>
          <FormattedMessage id="header.skills" />
        </NavLink>
        <NavLink
          activeClassName={styles.active}
          to={Routes.Projects}
          isActive={(match, location) => {
            if (match) {
              return true;
            }

            return (
              location.pathname.startsWith('/connectors') ||
              location.pathname.startsWith('/workinstructions') ||
              location.pathname.startsWith('/rules') ||
              location.pathname.startsWith('/functions')
            );
          }}
        >
          <FormattedMessage id="projects" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Devices}>
          <FormattedMessage id="header.devices" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Databases}>
          <FormattedMessage id="header.databases" />
        </NavLink>
        {isWorkbenchEnabled && (
          <NavLink activeClassName={styles.active} to={Routes.Workbench}>
            <FormattedMessage id="header.workbench" />
          </NavLink>
        )}
        <NavLink activeClassName={styles.active} to={Routes.Locations}>
          <FormattedMessage id="header.locations" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={`/settings/${GlobalSettingsTabs.NETWORK_SETUP}`}>
          <FormattedMessage id="header.settings" />
        </NavLink>
        <UserActions />
      </nav>
    </div>
  );
};

export default React.memo(HeaderNavigation);
