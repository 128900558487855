import { defineMessage } from 'react-intl';

import { getIntl } from 'providers/IntlProvider';

import axios, { AxiosError } from 'axios';
import { set } from 'lodash';
import { BASE_URL, LOGIN_TOKEN_LOCAL_STORAGE_KEY } from '../../globals';

export const getToken = () => localStorage.getItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY);

interface BackendErrorResponse {
  success: false;
  error?: {
    message: string;
    additionalData?: {
      errors?;
    };
  };
  data?;
  errorMessage: string;
}

export type ApiBackendError = AxiosError<BackendErrorResponse>;

export const parseBackendErrorMessage = (error: ApiBackendError): string => {
  try {
    const backendError = error.response?.data;
    const errorMessage = backendError?.errorMessage || error.message;
    const intl = getIntl();

    const templateValues = getIntlTemplateValues();
    /**
     * Translates error message replacing template placeholders with the intl values
     * For example, "You can't delete languages that are still in use: {de}, {it}" will be replaced with
     * "You can't delete languages that are still in use: German, Italian"
     * IMPORTANT: placeholder keys can not contain dots! Keys like 'global.languages.de' is not allowed!
     */
    const intlError = intl.formatMessage(
      defineMessage({
        id: 'Unkown Error', // just stub, intl.formatMessage does not work without defining id
        defaultMessage: Array.isArray(errorMessage) ? errorMessage.join(',') : errorMessage,
      }),
      templateValues,
    );

    return intlError;
  } catch (e) {
    // If we can't parse JSON, just return the initial error message
    return error.message;
  }
};

/**
 * Filters intl messages for replacement in errors and transforms intl keys to not contain the dots
 * intl.formatMessage does not allow to use replace template values a the key containing dots, eg 'global.languages.en'
 */
const getIntlTemplateValues = (): Record<string, string> => {
  const intl = getIntl();

  const intlMessageMap = new Map<string, string>();
  Object.entries(intl.messages).forEach(([intlKey, intlMessage]) => {
    if (intlKey.startsWith('global.languages.')) {
      const languageCode = intlKey.split('.').pop();
      if (languageCode) {
        intlMessageMap.set(languageCode, intlMessage);
      }
    }
  });

  return Object.fromEntries(intlMessageMap);
};

declare module 'axios' {
  interface AxiosRequestConfig {
    incognito?: boolean;
  }
}

const api = axios.create({
  baseURL: BASE_URL.replace('/$', ''), // Remove a trailing slash to prevent issues on different envs
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (!config.incognito && token) {
    set(config, 'headers.accesstoken', token);
  }
  set(config, 'headers.Accept', 'application/json');

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const parsedError = { ...error, message: parseBackendErrorMessage(error) };

    throw parsedError;
  },
);

export { api };
