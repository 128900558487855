// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageLoader-module__container___Dq3dB{width:100%;height:100%;display:flex;justify-content:center;align-items:center}.PageLoader-module__loader___uvqDC{display:inline-block;position:relative;width:80px;height:80px}.PageLoader-module__loader___uvqDC div{position:absolute;top:33px;width:13px;height:13px;border-radius:50%;background:#cdd2e0;animation-timing-function:cubic-bezier(0, 1, 1, 0)}.PageLoader-module__loader___uvqDC div:nth-child(1){left:8px;animation:PageLoader-module__loader1___PbCel .6s infinite}.PageLoader-module__loader___uvqDC div:nth-child(2){left:8px;animation:PageLoader-module__loader2___be5oX .6s infinite}.PageLoader-module__loader___uvqDC div:nth-child(3){left:32px;animation:PageLoader-module__loader2___be5oX .6s infinite}.PageLoader-module__loader___uvqDC div:nth-child(4){left:56px;animation:PageLoader-module__loader3___IwwPr .6s infinite}@keyframes PageLoader-module__loader1___PbCel{0%{transform:scale(0)}100%{transform:scale(1)}}@keyframes PageLoader-module__loader3___IwwPr{0%{transform:scale(1)}100%{transform:scale(0)}}@keyframes PageLoader-module__loader2___be5oX{0%{transform:translate(0, 0)}100%{transform:translate(24px, 0)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PageLoader-module__container___Dq3dB`,
	"loader": `PageLoader-module__loader___uvqDC`,
	"loader1": `PageLoader-module__loader1___PbCel`,
	"loader2": `PageLoader-module__loader2___be5oX`,
	"loader3": `PageLoader-module__loader3___IwwPr`
};
export default ___CSS_LOADER_EXPORT___;
