/* eslint-disable complexity */
import moment from 'moment';
import { AnyAction } from 'redux';

import type { LoginPOSTResponse } from '@workerbase/types/api/auth';
import { Languages } from '@workerbase/types/Settings';
import { LoggedUser } from 'services/types/User';

import { LoginActions } from './actions';

export type LoginState = Readonly<{
  user: LoggedUser | null;
  token: string | null;
  isAuthenticated: boolean;
  tokenTTLDate: string | null;
  loginError: string | null;
  loginSSOError: string | null;
  isLoadingInitialListConfig: boolean;
}>;

export const initialState: LoginState = {
  user: null,
  token: null,
  isAuthenticated: false,
  tokenTTLDate: null,
  loginError: null,
  loginSSOError: null,
  isLoadingInitialListConfig: false,
};

interface LoginFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: LoginState = initialState, action: AnyAction): LoginState => {
  switch (action.type) {
    case LoginActions.LOGIN_SSO_SUCCESS: {
      const payload = action.payload as LoginPOSTResponse;
      return {
        ...state,
        user: {
          id: payload.user._id,
          firstName: payload.user.firstName,
          lastName: payload.user.lastName,
          language: payload.user.language as Languages,
          disableDeviceLock: payload.user.disableDeviceLock || false,
          isDeveloper: payload.user.isDeveloper || false,
          isRootAdministrator: payload.user.superUser || false,
          email: payload.user.email,
          roleIds: payload.user.roles || [],
          isOnPremise: payload.isOnPremise || false,
          isWorkbenchEnabled: payload.isWorkbenchEnabled || false,
          isMyWorkEnabled: payload.isMyWorkEnabled || false,
        },
        isAuthenticated: true,
        token: payload.loginToken,
        tokenTTLDate: moment().add(payload.tokenTTLInMs, 'milliseconds').toISOString(),
        loginError: null,
        loginSSOError: null,
      };
    }
    case LoginActions.LOGIN_SSO_FAILURE: {
      const payload = action.payload as LoginFailureActionPayload;
      return {
        ...initialState,
        loginSSOError: payload.errorMessage,
      };
    }
    case LoginActions.LOGIN_USER_SUCCESS: {
      const payload = action.payload as LoginPOSTResponse;
      return {
        ...state,
        user: {
          id: payload.user._id,
          firstName: payload.user.firstName,
          lastName: payload.user.lastName,
          language: payload.user.language as Languages,
          disableDeviceLock: payload.user.disableDeviceLock || false,
          isDeveloper: payload.user.isDeveloper || false,
          isRootAdministrator: payload.user.superUser || false,
          email: payload.user.email,
          roleIds: payload.user.roles || [],
          isOnPremise: payload.isOnPremise || false,
          isWorkbenchEnabled: payload.isWorkbenchEnabled || false,
          isMyWorkEnabled: payload.isMyWorkEnabled || false,
        },
        isAuthenticated: true,
        token: payload.loginToken,
        tokenTTLDate: moment().add(payload.tokenTTLInMs, 'milliseconds').toISOString(),
        loginError: null,
      };
    }
    case LoginActions.LOGIN_USER_UPDATE_DATA: {
      const updatedUser = {
        isOnPremise: state.user?.isOnPremise,
        isWorkbenchEnabled: state.user?.isWorkbenchEnabled,
        isMyWorkEnabled: state.user?.isMyWorkEnabled,
        ...action.payload.user,
      } as LoggedUser;

      return {
        ...state,
        user: updatedUser,
      };
    }
    case LoginActions.LOGIN_USER_FAILURE: {
      const payload = action.payload as LoginFailureActionPayload;
      return {
        ...initialState,
        loginError: payload.errorMessage,
      };
    }
    case LoginActions.SET_IS_LOADING_INITIAL_LIST_CONFIG: {
      const isLoading = action.payload as boolean;

      return { ...state, isLoadingInitialListConfig: isLoading };
    }
    case LoginActions.LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
