// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button-module__button___b_aG7{font-family:Proxima Nova;font-style:normal;font-weight:bold;font-size:16px;line-height:20px;letter-spacing:0px;min-height:40px;padding:8px 18px;border-radius:6px;display:flex;align-items:center}.Button-module__button___b_aG7:hover{cursor:pointer}.Button-module__button___b_aG7>*:not(:last-child){margin-right:8px}.Button-module__disableFocus___ZiEVV:focus{outline:0}.Button-module__fullWidth___aAQf2{width:100%}.Button-module__center___xlAst{justify-content:center}.Button-module__disabled___HULEw{opacity:.4}.Button-module__disabled___HULEw:hover{cursor:not-allowed}.Button-module__primary___taWj2{background-color:#2660e6;color:#fff}.Button-module__primary___taWj2:hover,.Button-module__primary___taWj2:active{background-color:#174dca}.Button-module__secondary___sMAVa{background-color:#e6eeff;color:#174dca}.Button-module__secondary___sMAVa:hover,.Button-module__secondary___sMAVa:active{background-color:#ccdbff}.Button-module__light___hBIk1{background-color:#fff;border-color:#fff;color:#174dca}.Button-module__light___hBIk1:hover,.Button-module__light___hBIk1:active{background-color:#ccdbff}.Button-module__outline___dK5VZ{background-color:#fff;color:#174dca;border:solid thin #cdd2e0}.Button-module__outline___dK5VZ:hover,.Button-module__outline___dK5VZ:active{background-color:#ccdbff;border-color:#79a1ff}.Button-module__danger___spYej{background-color:#feebe1;border-color:#feebe1;color:#ff6868}.Button-module__danger___spYej:hover,.Button-module__danger___spYej:active{background-color:#fed7c3}.Button-module__tertiary___rHCFt{background-color:#fff;border:none;color:#174dca}.Button-module__tertiary___rHCFt:hover,.Button-module__tertiary___rHCFt:active{background-color:#e3e8f2}.Button-module__transparent___OGHfN{background-color:rgba(0,0,0,0);border:none;color:#174dca}.Button-module__icon___J9wTK{padding-right:8px}.Button-module__disabled___HULEw:hover{cursor:not-allowed}object{height:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button-module__button___b_aG7`,
	"disableFocus": `Button-module__disableFocus___ZiEVV`,
	"fullWidth": `Button-module__fullWidth___aAQf2`,
	"center": `Button-module__center___xlAst`,
	"disabled": `Button-module__disabled___HULEw`,
	"primary": `Button-module__primary___taWj2`,
	"secondary": `Button-module__secondary___sMAVa`,
	"light": `Button-module__light___hBIk1`,
	"outline": `Button-module__outline___dK5VZ`,
	"danger": `Button-module__danger___spYej`,
	"tertiary": `Button-module__tertiary___rHCFt`,
	"transparent": `Button-module__transparent___OGHfN`,
	"icon": `Button-module__icon___J9wTK`
};
export default ___CSS_LOADER_EXPORT___;
