// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainContainer-module__container___fNBB3{padding:28px 40px;flex-grow:1;display:flex}.MainContainer-module__container___fNBB3>div{flex-grow:1;display:flex;flex-direction:column;max-width:100%}.MainContainer-module__container___fNBB3>div header{flex-grow:0}.MainContainer-module__container___fNBB3>div header+div{flex-grow:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainContainer-module__container___fNBB3`
};
export default ___CSS_LOADER_EXPORT___;
